/* app/design/frontend/Ilio/saphore/Ilio_Theme/templates/footer.phtml */
.footer {
    background: $color-bg-1;
    padding-top: 60px;
    padding-bottom: 100px;
    @include element('container') {
        margin-top: 70px;
    }
}

/* app/design/frontend/Ilio/saphore/Ilio_Theme/templates/footer/pictos.phtml */
.f-pictos {
    @include element('icon') {
        margin: 0 auto 30px auto;
        @include modifier('1') {
            @include retina-sprite($footer-picto-01-group);
        }
        @include modifier('2') {
            @include retina-sprite($footer-picto-02-group);
        }
        @include modifier('3') {
            @include retina-sprite($footer-picto-03-group);
        }
        @include modifier('4') {
            @include retina-sprite($footer-picto-04-group);
        }
        @include modifier('5') {
            @include retina-sprite($footer-picto-05-group);
        }
    }

    @include element('title') {
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        color: white;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 20px;
    }
}

/* app/design/frontend/Ilio/saphore/Ilio_Theme/templates/footer/links.phtml */
.f-links {
    margin: 50px 0;
    padding: 40px 0;
    border-top: 1px solid $color-text-2;
    border-bottom: 1px solid $color-text-2;
    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        text-align: center;
        li {
            display: inline-block;
            padding: 0 50px;
            margin-bottom: 0;
            @media screen and (max-width: 991px) {
                padding: 0 20px;
            }
            @media screen and (max-width: 767px) {
                display: block;
                padding: 0;
                &:not(:last-child) {
                    margin-bottom: 20px;
                }
            }
            a {
                font-size: 16px;
                line-height: 1;
                font-family: "Poppins", sans-serif;
                color: white;
                font-weight: 500;
                text-transform: uppercase;
                -webkit-transition: all 0.3s ease;
                -moz-transition: all 0.3s ease;
                -ms-transition: all 0.3s ease;
                -o-transition: all 0.3s ease;
                transition: all 0.3s ease;
            }
        }
    }
}

/* app/design/frontend/Ilio/saphore/Ilio_Theme/templates/footer/description.phtml */
.f-description {
    @include element('title') {
        font-size: 24px;
        line-height: 1;
        font-family: "Poppins", sans-serif;
        text-transform: uppercase;
        font-weight: 500;
        color: $color-text-5;
        margin-top: 0;
        margin-bottom: 40px;
    }

    @include element('text') {
        font-size: 14px;
        line-height: 1.8;
        font-family: "Poppins", sans-serif;
        color: $color-text-5;
        text-align: justify;
    }

    @include element('bottom') {
        display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
        display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
        display: -ms-flexbox; /* TWEENER - IE 10 */
        display: -webkit-flex;
        display: flex;
        -webkit-box-align: center;
        -webkit-flex-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
        margin-bottom: 50px;
        @media screen and (max-width: 991px) {
            display: block;
        }
    }

    @include element('flag') {
        @include retina-sprite($footer-unioneuropeenne-group);
        float: left;
        margin-right: 36px;
        flex-grow: 0;
        flex-shrink: 0;
        @media screen and (max-width: 991px) {
            float: none;
            margin: 0 auto;
        }
    }

    @include element('flag-text') {
        font-size: 14px;
        line-height: 1.8;
        font-family: "Poppins", sans-serif;
        color: white;
        text-align: justify;
    }
}

/* app/design/frontend/Ilio/saphore/Ilio_Theme/templates/footer/sitemap.phtml */
.f-sitemap {
    @include element('title') {
        font-size: 18px;
        font-family: "Poppins", sans-serif;
        font-weight: 500;
        text-transform: uppercase;
        margin-top: 0;
        margin-bottom: 45px;
        color: white;
    }

    ul {
        margin-bottom: 50px;
        li {
            a {
                font-size: 14px;
                font-family: "Poppins", sans-serif;
                color: white;
                -webkit-transition: all 0.3s ease;
                -moz-transition: all 0.3s ease;
                -ms-transition: all 0.3s ease;
                -o-transition: all 0.3s ease;
                transition: all 0.3s ease;
                &:before {
                    content: '-';
                    margin-right: 5px;
                }
            }
        }
    }
}

/* app/design/frontend/Ilio/saphore/Ilio_Theme/templates/footer/mentions.phtml */
.f-mentions {
    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        li {
            display: inline-block;
            margin-bottom: 0;
            a {
                font-size: 14px;
                line-height: 1;
                font-family: "Poppins", sans-serif;
                color: $color-text-4;
                font-weight: 500;
                text-transform: uppercase;
                -webkit-transition: all 0.3s ease;
                -moz-transition: all 0.3s ease;
                -ms-transition: all 0.3s ease;
                -o-transition: all 0.3s ease;
                transition: all 0.3s ease;
            }

            &:not(:last-child):after {
                content: '-';
                margin: 0 3px 0 5px;
                font-size: 14px;
                line-height: 1;
                font-family: "Poppins", sans-serif;
                color: $color-text-4;
                font-weight: 500;
                @media screen and (max-width: 767px) {
                    content: none;
                }
            }
            @media screen and (max-width: 767px) {
                display: block;
            }
        }
    }
    @media screen and (max-width: 767px) {
        text-align: center;
    }
}

#notice-cookie {
    position: fixed;
    bottom: 0;
    left: 0;
    background: #2b2b28;
    padding: 17px 50px;
    width: 100%;
    z-index: 10000;

    .notice-cookie-content__big {
        font-weight: bold;
        font-size: 14px;
    }

    .notice-cookie-content__text {
        @media screen and (max-width: 991px) {
            margin-bottom: 20px;
        }
    }

    .notice-cookie-content__small {
        font-size: 12px;
    }

    .notice-cookie-content__btns {
        display:flex;
        justify-content: flex-end;

        @media screen and (max-width: 991px) {
            justify-content: center;
        }

        a:last-of-type {
            margin-left: 25px;
        }
    }
}