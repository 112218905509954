$elementSeparator: '__';
$modifierSeparator: '--';

@mixin block($block) {
    .#{$block} {
        @content;
    }
}

@mixin element($element) {
    @at-root {
        #{&}#{$elementSeparator+$element} {
            @content;
        }
    }
}

@mixin modifier($modifier) {
    @at-root {
        #{&}#{$modifierSeparator+$modifier} {
            @content;
        }
    }
}

@mixin placeholder() {
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        @content;
    }
    &:-ms-input-placeholder { /* IE 10+ */
        @content;
    }
    &:-moz-placeholder { /* Firefox 18- */
        @content;
        opacity: 1;
    }
    &::-moz-placeholder { /* Firefox 19+ */
        @content;
        opacity: 1;
    }
}