.cms-content {
    h2 {
        font-family: "Lato", sans-serif;
        font-size: 18px;
        font-weight: 700;
        color: $color-text-1;
    }

    p {
        font-family: "Lato", sans-serif;
        font-size: 14px;
        color: $color-text-1;
    }

    ul, ol {
        font-family: "Lato", sans-serif;
        font-size: 14px;
        color: $color-text-1;
        list-style: initial;
        padding-left: 40px;
        li {
            list-style: initial !important;
        }
    }

    table {
        td, th {
            font-family: "Lato", sans-serif;
            font-size: 14px;
            color: $color-text-1;
        }
    }
}