h1 {
    font-family: "Poppins", sans-serif;
    font-size: 36px;
    font-weight: 500;
    color: $color-primary;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 65px;
    @media screen and (max-width: 767px) {
        font-size: 26px;
        margin-bottom: 20px;
    }
}

.page-main {
    > .page-title-wrapper {
        .page-title {
            font-family: "Poppins", sans-serif;
            font-size: 36px;
            font-weight: 500;
            color: $color-primary;
            text-align: center;
            text-transform: uppercase;
            margin-bottom: 65px;
            @media screen and (max-width: 767px) {
                font-size: 26px;
                margin-right: 0;
            }
        }
    }
}

.dashboard-title {
    font-family: "Poppins", sans-serif;
    font-size: 36px;
    font-weight: 500;
    color: $color-primary;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 65px;
    @media screen and (max-width: 767px) {
        font-size: 26px;
        margin-right: 0;
    }
}

.h1 {
    @include element('dashboard') {
        font-size: 24px;
        text-align: left;
    }
}

.h2 {
    @include element('title') {
        font-family: "Poppins", sans-serif;
        font-size: 36px;
        line-height: 1;
        font-weight: 400;
        text-transform: uppercase;
        color: $color-text-2;
        margin-top: 0;
        margin-bottom: 34px;
        text-align: center;
        @media screen and (max-width: 767px) {
            font-size: 26px;
        }
    }

    @include element('subtitle') {
        font-family: "Poppins", sans-serif;
        font-size: 18px;
        line-height: 1;
        font-weight: 400;
        text-transform: uppercase;
        color: $color-primary;
    }
}