.breadcrumbs {
    text-align: left !important;
    margin-bottom: 0;

    .items {
        font-size: 14px;
    }

    .item {
        text-transform: none !important;
        a {
            color: $color-text-4;
            font-style: italic;
            text-decoration: underline;
            &:visited {
                color: $color-text-4;
            }
            &:hover, &:active {
                color: $color-primary;
            }
        }

        strong {
            font-weight: 700;
            font-style: italic;
            color: $color-text-4;
        }

        &:last-child {
            a {
                font-weight: bold;
            }
        }
    }
}