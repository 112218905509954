.tyre-sel {
    @include element('form') {
        @include modifier('single') {
            input[type=text] {
                margin-bottom: 0 !important;
            }

            .btn--full {
                padding: 14px 15px;
            }
        }
    }

    @include element('container') {
        background: white;
        position: relative;
    }

    @include element('fields') {
        padding: 40px 100px;

        input[type=text] {
            background: url(../images/tyre-search-icon.png) right 9px center no-repeat #fff;

            font-family: "Poppins", sans-serif;
            font-size: 14px;
            line-height: 50px;
            font-weight: 400;
            color: $color-text-2;

            margin-bottom: 25px;
            padding: 0 40px 0 15px;
            @include placeholder {
                font-family: "Poppins", sans-serif;
                font-size: 14px;
                line-height: 50px;
                font-weight: 400;
                color: $color-text-2;
            }
        }

        select {
            background: url(../images/icn_arrow_select.png) right 9px center no-repeat #fff;
            font-family: "Poppins", sans-serif;
            font-size: 14px;
            line-height: 1;
            font-weight: 400;
            color: $color-text-2;
            margin-bottom: 20px;
            padding: 0 25px 0 15px;
        }

        .label_tyre {
            font-size: 14px;
            margin-bottom: 5px;
            padding-left: 10px;
        }

        @media screen and (max-width: 767px) {
            padding: 15px;
        }
    }

    @include element('submit') {
        font-weight: 700 !important;
        i {
            font-size: 20px;
            position: absolute;
            margin-left: 20px;
        }

        &:hover {
            border: 1px solid darken($color-primary, 5%) !important;
        }
    }

    @include element('cats') {
        border-bottom: 1px solid #cdcdcd;
        text-align: center;
    }

    @include element('cat') {
        display: inline-block;
        position: relative;

        input {
            opacity: 0;
            visibility: hidden;
            position: absolute;
            top: -9999;
            &:checked + label {
                background: $color-primary;
                color: white;
            }
        }

        label {
            background: white;
            cursor: pointer;

            font-family: "Poppins", sans-serif;
            font-size: 14px;
            line-height: 1;
            font-weight: 500;
            color: $color-text-2;
            text-align: center;

            padding: 20px 40px;
            margin: 0;

            -webkit-transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            -ms-transition: all 0.3s ease;
            -o-transition: all 0.3s ease;
            transition: all 0.3s ease;

            &:hover {
                background: $color-primary;
                color: white;
            }

            @media screen and (max-width: 767px) {
                width: 100%;
            }
        }

        @media screen and (max-width: 767px) {
            display: block;
        }
    }
}