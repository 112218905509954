.r-products {
    @include element('title') {
        font-family: "Poppins", sans-serif;
        font-size: 18px;
        font-weight: 600;
        color: $color-primary;
        text-transform: uppercase;
        line-height: 1;
        margin-top: 0;
        margin-bottom: 40px;
    }
}

.r-product {
    @include element('item') {
        overflow: hidden;
        clear: both;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;

        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        &:not(:last-child) {
            margin-bottom: 50px;
        }

        &:hover {
            text-decoration: none;

            .r-product {
                @include element('img') {
                    img {
                        border-color: $color-primary;
                    }
                }

                @include element('title') {
                    color: $color-primary;
                }
            }
        }
    }

    @include element('column') {
        display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
        display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
        display: -ms-flexbox; /* TWEENER - IE 10 */
        display: -webkit-flex;
        display: flex;
        justify-content: center;
        flex-flow: column;
        @media screen and (max-width: 767px) {
            display: block;
        }
    }

    @include element('img') {
        position: relative;
        img {
            border: 1px solid #d7d7d7;
            transition: all 0.3s ease;
        }
    }

    @include element('title') {
        font-family: "Poppins", sans-serif;
        font-size: 18px;
        font-weight: 600;
        color: $color-text-9;
        margin-top: 0;
        margin-bottom: 16px;
        transition: all 0.3s ease;
        @media screen and (max-width: 767px) {
            margin-top: 10px;
        }
    }

    @include element('price') {
        font-family: "Poppins", sans-serif;
        font-size: 16px;
        font-weight: 600;
        color: $color-secondary;
    }
}