.checkout-index-index {
    .nav-sections {
        display: block;
    }
}

.field-select-billing select option:last-child {
    display: none;
}

.shipping-address-content {
    @media screen and (min-width: 768px) and (max-width: 991px)  {
        min-height: 150px;
    }

    @media screen and (min-width: 992px) and (max-width: 1199px)  {
        min-height: 180px;
    }

    @media screen and (min-width: 1200px) {
        min-height: 210px;
    }
}