/* app/design/frontend/Ilio/saphore/Ilio_Theme/templates/homepage/info-blocks.phtml */
.info-block {
    padding: 50px;

    a {
        display: block;
        overflow: hidden;
        &:hover, &:focus {
            text-decoration: none;
            .info-block__title, .info-block__text, .info-block__text p {
                font-weight: 500;
            }
        }
    }

    @include element('title') {
        font-family: "Poppins", sans-serif;
        font-size: 18px;
        line-height: 1;
        font-weight: 400;
        color: white;
        text-transform: uppercase;
        margin-top: 0;
        transition: all 0.3s ease;
    }

    @include element('text') {
        transition: all 0.3s ease;
        p {
            font-family: "Poppins", sans-serif;
            font-size: 12px;
            color: white;
            font-weight: 300;
            line-height: 1.6;
            transition: all 0.3s ease;
        }
    }

    @include element('content') {
        display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
        display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
        display: -ms-flexbox; /* TWEENER - IE 10 */
        display: -webkit-flex;
        display: flex;
        -webkit-box-align: center;
        -webkit-flex-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
        justify-content: center;
    }

    @include element('img') {
        flex-grow: 0;
        flex-shrink: 0;
        margin-right: 16px;
    }

    // Modifiers
    @include modifier('1') {
        background: #2b90f8;
        padding-left: 15px;
        padding-right: 100px;
    }

    @include modifier('2') {
        background: #0eadf7;
    }

    @include modifier('3') {
        background: #34cdff;
    }

    // Media queries
    @media (max-width: 1199px) {
        padding: 50px 25px;
        @include modifier('1') {
            padding-right: 25px;
        }
    }
}

.info-blocks {
    position: relative;
    opacity: 0;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    &:before {
        content: '';
        background: #2b90f8;
        position: absolute;
        left: 0;
        right: 50%;
        top: 0;
        bottom: 0;
        display: block;
    }
    &:after {
        content: '';
        background: #34cdff;
        position: absolute;
        left: 50%;
        right: 0;
        top: 0;
        bottom: 0;
        display: block;
        z-index: -1;
    }

    @media (max-width: 991px) {
        &:before, &:after {
            content: none;
        }
        .container {
            width: 100%;
        }
    }
}