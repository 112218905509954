/* app/code/Ilio/Theme/view/frontend/web/template/minicart/content.html */
.minicart-wrapper {
    .block-minicart {
        min-width: 0;
    }

    .actions {
        a {
            color: white;
            &:hover, &:focus, &:active, &:visited {
                color: white;
            }
        }
    }
}

.w-item {
    position: relative;
    .total-qty {
        position: absolute;
        top: 0;
        left: 38px;
        float: none;
        margin: 0;
    }
}

.block-minicart {
    .block-content {
        > .actions {
            > .primary {
                margin-left: 0;
                margin-right: 0;
            }
        }
    }
}

.minicart {
    @include modifier('degraded') {
        #top-cart-btn-checkout {
            display: none;
        }

        .subtotal {
            visibility: hidden;
        }
    }
    @include modifier('notlogged') {
        #top-cart-btn-checkout {
            display: none;
        }
    }
}