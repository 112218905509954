/* app/design/frontend/Ilio/saphore/Magento_Customer/templates/account/navigation.phtml */
$fa-icon: '\f18e' '\f1da' '\f0c2' '\f0ec' '\f086' '\f0f3';
// f18e : fa-arrow-circle-o-right
// f1da : fa-history
// f0ec : fa-exchange
// f086 : fa-comments
// f0f3 : fa-bell
.l-navigation {
    background: #ededed;
    padding-top: 25px;
    padding-bottom: 25px;
    ul {
        li {
            > a {
                display: block;
                padding: 25px 50px;
                background: #ededed;

                font-family: "Poppins", sans-serif;
                font-size: 14px;
                font-weight: 500;
                color: $color-primary;
                -webkit-transition: all 0.3s ease;
                -moz-transition: all 0.3s ease;
                -ms-transition: all 0.3s ease;
                -o-transition: all 0.3s ease;
                transition: all 0.3s ease;

                strong {
                    font-family: "Poppins", sans-serif;
                    font-size: 14px;
                    font-weight: 500;
                    color: $color-primary;
                }

                &:hover, &:focus {
                    background: #fafafa;
                    text-decoration: none;
                }
                @media screen and (max-width: 767px) {
                    padding: 20px;
                }
            }
            > strong {
                display: block;
                padding: 25px 50px;
                background: #fafafa;

                font-family: "Poppins", sans-serif;
                font-size: 14px;
                font-weight: 500;
                color: $color-primary;
                @media screen and (max-width: 767px) {
                    padding: 20px;
                }
            }

            @each $icon in $fa-icon {
                $i: index($fa-icon, $icon);

                &:nth-child(#{$i}) {
                    a, strong {
                        &:before {
                            width: 30px;
                            content: $icon;
                            font-family: 'FontAwesome';
                            float: left;
                            margin-right: 14px;
                            font-size: 26px;
                            margin-top: -8px;
                            position: relative;
                        }
                    }
                }
            }

            &.current {
                > a {
                    &:before {
                        content: none;
                        display: none;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 991px) {
        margin-bottom: 20px;
    }
}

/* app/design/frontend/Ilio/saphore/Magento_Customer/templates/account/dashboard/info.phtml */
.dashboard {
    @include element('block') {
        p, address {
            font-family: "Poppins", sans-serif;
            font-size: 14px;
            color: $color-text-1;
        }
    }

    @include element('title') {
        font-family: "Poppins", sans-serif;
        font-size: 19px;
        font-weight: 500;
        text-align: left;
        color: $color-primary;
        text-transform: uppercase;
    }

    @include element('subtitle') {
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        font-weight: 500;
        text-align: left;
        color: $color-text-1;
    }

    @include element('links') {
        a {
            display: block;
            font-family: "Poppins", sans-serif;
            font-size: 12px;
            font-weight: 500;
            color: $color-secondary;
            text-decoration: underline;
            margin-bottom: 10px;
            i {
                float: left;
                margin-top: 2px;
                margin-right: 5px;
                &.fa-lock {
                    margin-right: 7px;
                }
            }
        }
    }
}