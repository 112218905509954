/* app/design/frontend/Ilio/saphore/Ilio_Theme/templates/homepage/domains.phtml */
#home-domains {
    padding-top: 90px;
    padding-bottom: 50px;
    position: relative;

    &:before {
        content: '';
        display: block;
        background-image: url('../images/tracteur-1.png');
        width: 435px;
        height: 433px;

        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);

        @media screen and (max-width: 991px) {
            display: none;
        }
    }

    &:after {
        content: '';
        display: block;
        background-image: url('../images/tracteur-2.png');
        width: 458px;
        height: 519px;

        position: absolute;
        top: 70%;
        left: 0;

        z-index: -1;

        @media screen and (max-width: 991px) {
            display: none;
        }
    }
}

.h-domain {
    @include element('item') {
        display: block;
        margin-bottom: 30px;

        @media screen and (min-width: 992px) {
            &:nth-child(3n+4) {
                clear: both;
            }
        }
        @media screen and (min-width: 768px) and (max-width: 991px) {
            &:nth-child(2n+3) {
                clear: both;
            }
        }
    }

    @include element('content') {
        background: white;
        border: 1px solid $color-border-2;
        padding: 50px;
        display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
        display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
        display: -ms-flexbox; /* TWEENER - IE 10 */
        display: -webkit-flex;
        display: flex;
        -webkit-box-align: center;
        -webkit-flex-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
        justify-content: center;
        transition: all 0.3s ease;

        &:hover, &:focus {
            text-decoration: none;
            border-color: $color-primary;

            h3 {
                color: $color-primary;
            }
        }

        img {
            margin-right: 20px;
        }

        h3 {
            font-family: "Poppins", sans-serif;
            font-size: 28px;
            line-height: 1.1;
            font-weight: 400;
            text-transform: uppercase;
            color: $color-text-2;
            margin: 0;
            transition: all 0.3s ease;
            @media screen and (max-width: 767px) {
                font-size: 18px;
            }
        }

        @media screen and (max-width: 991px) {
            padding: 30px;
        }

        @media screen and (max-width: 767px) {
            padding: 20px;
        }
    }
}