.btn {
    background: $color-primary;
    padding: 15px 25px;
    border-radius: 0;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: white;
    border: 1px solid $color-primary;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;

    &:hover, &:focus {
        background: darken($color-primary, 5%);
        color: white;
    }

    &:visited {
        color: white;
    }

    @include modifier('empty') {
        background: white;
        border: 1px solid $color-primary;
        color: $color-primary !important;
        &:hover {
            border: 1px solid $color-primary;
            color: white !important;
        }
    }

    @include modifier('orange') {
        background: $color-secondary;
        border-color: $color-secondary;
        &:hover, &:focus {
            background: darken($color-secondary, 5%);
            border: 1px solid darken($color-secondary, 5%);
            color: white;
        }
    }

    @include modifier('large') {
        padding: 15px 50px;
        max-width: 100%;

        @media screen and (max-width: 767px) {
            width: 100%;
            padding: 15px 0;
        }
    }

    @include modifier('full') {
        padding: 15px;
        width: 100%;
        text-align: center;
    }

    @include modifier('all') {
        display: inline-block;
        font-family: "Poppins", sans-serif;
        font-size: 16px;
        line-height: 1;
        font-weight: 400;
        color: $color-text-3;
        text-transform: uppercase;
        padding: 20px 0;
        margin: 40px auto 0;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-bottom: 1px solid $color-text-3;
        -webkit-transition: all .3s ease;
        transition: all .3s ease;
        width: 270px;
        max-width: 100%;
        text-align: center;
        &:visited {
            color: $color-text-3;
        }

        &:hover, &:focus {
            text-decoration: none;
            color: $color-primary;
            border-bottom: 1px solid $color-primary;
        }
    }
}

.popup-cat-btn {
    position: absolute;
    top: -8px;
    left: 80px;
    right: 0;
    font-size: 25px;
}

@media screen and (max-width: 767px) {
    .btn {
        width: 100%;
        padding: 15px 0 !important;
        @include modifier('large') {
            padding: 15px 0 !important;
        }
    }

    .primary.pull-right {
        float: none !important;
    }
}