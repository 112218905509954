.form.password.reset,
.form.send.confirmation,
.form.password.forget,
.form.create.account {
    width: 100%;
    min-width: 0;
}

/* app/design/frontend/Ilio/saphore/Magento_Customer/templates/form/login.phtml */
/* app/design/frontend/Ilio/saphore/Magento_Customer/templates/newcustomer.phtml */
.login-container {
    .block {
        width: 100%;
        padding-top: 40px;
        .block-title {
            padding-bottom: 30px;
            strong {
                font-family: "Poppins", sans-serif;
                font-size: 24px !important;
                font-weight: 500;
                text-transform: uppercase;
                text-align: center;
                color: $color-text-2;
            }
        }
        &.block-new-customer {
            padding-bottom: 60px;
            padding-left: 100px;
            padding-right: 100px;
            background: $color-border-1;
            .block-new-customer__btn-top {
                margin-bottom: 10px;
            }

            .btn {
                width: 270px;
                padding-left: 10px;
                padding-right: 10px;
                color: white;
            }

            .block-title {
                border-color: $color-text-6;
            }

            @media screen and (max-width: 767px) {
                padding-left: 15px;
                padding-right: 15px;
                .btn {
                    width: 100%;
                }
            }
        }
    }

    .remind {
        font-family: "Lato", sans-serif;
        font-style: italic;
        font-size: 14px;
        color: $color-five;
        text-decoration: underline;
        text-transform: lowercase;
    }

    .fieldset {
        &:after {
            display: block;
            clear: both;
            text-align: right;
            font-family: "Lato", sans-serif;
            font-size: 14px;
            font-style: italic;
            color: $color-text-3;
            margin-top: 10px;

            @media screen and (max-width: 767px) {
                padding-top: 10px;
            }
        }
    }

    #login-form {
        .actions-toolbar {
            margin-bottom: 0;
        }
    }
}

/* app/design/frontend/Ilio/saphore/Magento_Customer/templates/form/forgotpassword.phtml */
.forgot-pw {
    input[type="email"] {
        width: calc(100% - 30px);
    }
}

.mb--25 {
    margin-bottom: 25px;
}