// Theme colors
$color-primary: #10acf4;
$color-secondary: #ff7200;
$color-three: #ffba00;
$color-four: #ff9600;
$color-five: #355a98;

// Text color
$color-text-1: #333333;
$color-text-2: #464646;
$color-text-3: #777777;
$color-text-4: #858585;
$color-text-5: #ababab;
$color-text-6: #999999;
$color-text-7: #605959;
$color-text-8: #5f5f5f;
$color-text-9: #191c21;

$color-border-1: #d8d8d8;
$color-border-2: #bdbdbd;

// Background colors
$color-bg-1: #2b2b28;