.table-order-items {
    thead {
        tr {
            th {
                padding: 0 0 10px 0;
                text-align: left;
                font-size: 14px;
                font-weight: 400;
                color: $color-text-3;
                &.subtotal {
                    text-align: left;
                }
            }
        }
    }
    tbody {
        tr {
            td {
                border: 1px solid #d7d7d7 !important;
                padding: 25px 20px;
                font-family: "Poppins", sans-serif;
                font-size: 14px;
                color: $color-text-9;
                &.total, &.price, &.subtotal {
                    font-weight: 600;
                    color: $color-secondary;
                    .price {
                        font-family: "Poppins", sans-serif;
                        font-size: 14px;
                        font-weight: 600;
                        color: $color-secondary;
                    }
                }
            }
        }
    }
}

.order-date {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    color: $color-text-1;
    .label {
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        color: $color-text-1;
    }
}

ul.items.order-links {
    display: none;
}

.order-details-items {
    float: none;
    padding: 0;
    border: 0;
    .order-title {
        > strong {
            font-family: "Poppins", sans-serif;
            font-size: 19px;
            font-weight: 600;
            color: $color-text-8;
            text-transform: uppercase;
        }
    }
}

.block-order-details-view {
    .block-title {
        float: none;
        text-align: left;
        margin-bottom: 10px;
        strong {
            font-family: "Poppins", sans-serif;
            font-size: 24px !important;
            font-weight: 600;
            text-transform: uppercase;
            color: $color-primary;
        }
    }

    .block-content {
        .box-title {
            font-family: "Poppins", sans-serif;
            font-size: 14px;
            font-weight: 600;
            color: $color-text-1;
        }
        .box-content {
            font-family: "Poppins", sans-serif;
            font-size: 14px;
            font-weight: 400;
            color: $color-text-1;
            address {
                font-family: "Poppins", sans-serif;
                font-size: 14px;
                font-weight: 400;
                color: $color-text-1;
            }
        }
    }
}

.sales-order-view {
    .page-title-wrapper {
        .page-title {
            font-size: 24px;
            text-align: left;
        }
    }
}

.ws-order-title {
    font-size: 24px;
}

.account .toolbar .pages {
    position: static;
}

@media screen and (max-width: 767px) {
    .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) > tbody > tr td,
    .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) > tbody > tr th {
        padding: 15px;
    }
}