.return {
    @include element('tabs') {
        margin-bottom: 20px;
        .btn {
            &:not(:last-child) {
                margin-right: 30px;
            }

            &:focus, &.active {
                background: $color-secondary !important;
                border-color: $color-secondary !important;
                color: white !important;
            }
        }
    }

}