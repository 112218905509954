.article {
    @include element('container') {
        margin-bottom: 70px;
        @media screen and (min-width: 1200px) {
            &:nth-child(4n+5) {
                clear: both;
            }
        }
        @media screen and (min-width: 992px) and (max-width: 1199px) {
            &:nth-child(3n+4) {
                clear: both;
            }
        }
        @media screen and (min-width: 768px) and (max-width: 991px) {
            &:nth-child(2n+3) {
                clear: both;
            }
        }

        .btn {
            @media screen and (max-width: 767px) {
                width: 100%;
            }
        }
    }

    @include element('img') {
        position: relative;
        overflow: hidden;
        padding-bottom: 100%;

        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;

        margin-bottom: 20px;
        img {
            position: absolute;
            opacity: 0;
            visibility: hidden;
        }

        @include modifier('home') {
            margin-bottom: 0;
        }
    }

    @include element('date') {
        font-family: "Lato", sans-serif;
        font-size: 12px;
        text-transform: uppercase;
        color: $color-text-1;
        margin-bottom: 10px;
        @include modifier('single') {
            margin-bottom: 30px;
        }
    }

    @include element('title') {
        font-family: "Poppins", sans-serif;
        font-size: 16px;
        font-weight: 500;
        color: $color-primary;
        text-transform: uppercase;
        margin-bottom: 20px;
        @include modifier('single') {
            text-align: left;
            margin-bottom: 15px;
        }
        @include modifier('home') {
            font-family: "Poppins", sans-serif !important;
            font-size: 24px !important;
            font-weight: 500 !important;
            color: $color-text-2 !important;
            text-transform: uppercase !important;
        }
    }

    @include element('excerpt') {
        font-family: "Lato", sans-serif;
        font-size: 14px;
        line-height: 1.6;
        color: $color-text-1;
        margin-bottom: 20px;
        text-transform: none;
        p, ul, li {
            font-family: "Lato", sans-serif;
            font-size: 14px;
            line-height: 1.6;
            color: $color-text-1;
            margin-bottom: 0;
            text-transform: none;
        }
    }

    @include element('btn') {
        color: white !important;
    }

    @include element('back') {
        display: inline-block;
        font-family: "Poppins", sans-serif;
        font-size: 16px;
        line-height: 1;
        font-weight: 500;
        color: $color-text-2;
        text-transform: uppercase;
        padding: 20px 40px;
        margin: 40px auto 0 auto;
        border-bottom: 1px solid $color-text-2;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
        &:visited {
            color: $color-text-2;
        }
        &:hover, &:focus {
            text-decoration: none;
            color: $color-primary;
            border-color: $color-primary;
        }
    }

    @include element('item') {
        @include modifier('home') {
            width: 100%;
            height: 100%;
        }
    }

    @include element('skew') {
        margin: 0 -70px;
        transform-origin: left center;
        background: #d8d8d8;
        transform: rotate(-5deg);
        margin-top: 200px;
        @media screen and (max-width: 767px) {
            margin-top: 40px;
        }
    }
}

.home-actus {
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    -o-transform: rotate(5deg);
    -ms-transform: rotate(5deg);
    transform: rotate(5deg);
    padding: 70px 0 50px;
    @media screen and (max-width: 767px) {
        padding: 50px 70px 30px;
    }
}