#home-products {
    padding-top: 80px;
    padding-bottom: 70px;
    background: white;
}

/* app/design/frontend/Ilio/saphore/Magento_Catalog/template/product/view/attributes.phtml */
.p-attribute {
    background: white;
    padding: 20px 30px;
    margin-bottom: 15px;

    @include element('title') {
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        font-weight: 600;
        color: $color-text-1;

        text-align: left;
        span {
            color: $color-primary;
        }
    }

    @include element('content') {
        position: relative;
        display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
        display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
        display: -ms-flexbox; /* TWEENER - IE 10 */
        display: -webkit-flex;
        display: flex;
        -webkit-box-align: center;
        -webkit-flex-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
    }
}

/* app/design/frontend/Ilio/saphore/Magento_Catalog/template/product/view/details.phtml */
.p-details {
    background: #e4e4e4;
    @include element('navigation') {
        border-bottom: 1px solid #a6a6a6;
        li {
            float: none;
            display: inline-block;
            *display: inline; /* ie7 fix */
            zoom: 1; /* hasLayout ie7 trigger */
            &.active {
                a {
                    color: $color-primary;
                    background: none;
                    border: none;
                    &:after {
                        content: '';
                        display: block;
                        width: 100%;
                        height: 5px;
                        background: #10acf4;
                        position: absolute;
                        left: 0;
                        bottom: -2px;
                    }
                    &:hover, &:focus {
                        color: $color-primary;
                        background: none;
                        border: none;
                    }
                }
            }
            a {
                font-family: "Poppins", sans-serif;
                font-size: 14px;
                font-weight: 600;
                text-transform: uppercase;
                color: $color-text-2;
                padding: 25px 45px 20px 45px;
                margin: 0;
                line-height: 1;
                border: none;
                background: none;
                &:hover, &:focus {
                    color: $color-primary;
                    background: none;
                    border: none;
                }
            }
        }
    }

    @include element('content') {
        padding: 30px;
    }
}