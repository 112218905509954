.account {
    .toolbar {
        .toolbar-amount {
            font-family: "Poppins", sans-serif;
            font-size: 14px;
            font-weight: 600;
            color: $color-text-3;
        }
    }
    .actions-toolbar {
        text-align: right;
        .back {
            font-size: 14px;
            color: $color-text-3;
            text-transform: uppercase;
            text-decoration: underline;
            -webkit-transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            -ms-transition: all 0.3s ease;
            -o-transition: all 0.3s ease;
            transition: all 0.3s ease;
            &:hover, &:focus {
                color: $color-primary;
            }
        }
    }
}

.sales-order-view {
    .order-status {
        font-size: 14px;
        font-weight: 700;
        font-style: italic;
        text-transform: lowercase;
        color: $color-secondary;
    }
}

.sales-order-history {
    .pages-items {
        width: 100%;
    }

    .toolbar-amount {
        float: none!important;
        margin-bottom: 20px;
    }

    .table-order-items {
        tbody {
            tr {
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
}