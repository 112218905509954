/* app/design/frontend/Ilio/saphore/Magento_Catalog/template/product/list.phtml */
.l-product {
    text-align: center;
    margin-bottom: 50px;

    &:hover {
        .l-product {
            @include element('img') {
                &:before {
                    opacity: 0.8;
                }
            }

            @include element('actions') {
                opacity: 1;
                visibility: visible;
                -webkit-transition: opacity 0.3s ease;
                -moz-transition: opacity 0.3s ease;
                -ms-transition: opacity 0.3s ease;
                -o-transition: opacity 0.3s ease;
                transition: opacity 0.3s ease;
            }
        }
    }

    @include element('img') {
        position: relative;
        margin-bottom: 20px;
        img {
            border: 1px solid #d7d7d7;
            transition: all 0.3s ease;
        }

        &:before {
            content: '';
            display: block;
            background: $color-primary;
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            -webkit-transition: opacity 0.3s ease;
            -moz-transition: opacity 0.3s ease;
            -ms-transition: opacity 0.3s ease;
            -o-transition: opacity 0.3s ease;
            transition: opacity 0.3s ease;
        }
    }

    @include element('title') {
        font-family: "Poppins", sans-serif;
        font-size: 18px;
        font-weight: 600;
        color: $color-text-9;
        margin-top: 0;
        margin-bottom: 0;
        text-align: center;
    }

    @include element('price') {
        font-family: "Poppins", sans-serif;
        font-size: 18px;
        font-weight: 600;
        color: $color-secondary;
        text-align: center;
    }

    @include element('form') {
        display: inline-block;
    }

    @include element('actions') {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
        display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
        display: -ms-flexbox; /* TWEENER - IE 10 */
        display: -webkit-flex;
        display: flex;
        -webkit-box-align: center;
        -webkit-flex-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
        justify-content: center;
        transition: opacity 0.5s ease;
    }

    @include element('icons') {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
    }

    @include element('icon') {
        display: inline-block;
        padding: 6px 10px;
        font-family: "Poppins", sans-serif;
        font-size: 12px;
        text-transform: uppercase;
        color: white;
        font-weight: 600;
        margin-right: 5px;

        @include modifier('new') {
            background: #bd2f48;
        }

        @include modifier('sale') {
            background: #ffd200;
            color: $color-text-1;
        }
    }

    @include element('btn') {
        position: relative;
        font-size: 20px;
        width: 50px;
        height: 50px;
        line-height: 50px;
        transition: all 0.3s ease;
        border: 1px solid white;
        i {
            transition: all 0.3s ease;
            color: $color-primary;
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            -moz-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            -o-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
        }

        &:hover, &:focus {
            border: 1px solid white;
            background: $color-primary;
            i {
                color: white;
            }
        }
    }

    @include element('short-description') {
        font-family: "Poppins", sans-serif;
        font-weight: 600;
        text-transform: uppercase;
        color: $color-primary;
    }

    // col-md-3
    @include modifier('md-3') {
        @media screen and (min-width: 992px) {
            &:nth-child(4n + 5) {
                clear: both;
            }
        }
        @media screen and (min-width: 768px) and (max-width: 991px) {
            &:nth-child(2n + 3) {
                clear: both;
            }
        }
    }

    @include modifier('md-4') {
        @media screen and (min-width: 992px) {
            &:nth-child(3n + 4) {
                clear: both;
            }
        }
        @media screen and (min-width: 768px) and (max-width: 991px) {
            &:nth-child(2n + 3) {
                clear: both;
            }
        }
    }
}

.filter {
    @include modifier('current') {
        background: #efefef;
        margin-bottom: 20px;

        .remove {
            &:before {
                content: '\f057' !important;
                font-family: 'FontAwesome' !important;
                color: $color-secondary !important;
                font-size: 14px !important;
            }
        }
    }

    .filter-current {
        .items {
            display: block !important;
        }
    }
}