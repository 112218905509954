body {
    font-family: "Lato", sans-serif;
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

// Define 5 columns layout for footer
.col-xs-15,
.col-sm-15,
.col-md-15,
.col-lg-15 {
    position: relative;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
}

.col-xs-30,
.col-sm-30,
.col-md-30,
.col-lg-30 {
    position: relative;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
}

.col-xs-15 {
    width: 20%;
    float: left;
}

.col-xs-30 {
    width: 40%;
    float: left;
}

.loading-mask {
    background: none;
}

#maincontent {
    margin-top: 40px;
}

@media (min-width: 768px) {
    .col-sm-15 {
        width: 20%;
        float: left;
    }

    .col-sm-30 {
        width: 40%;
        float: left;
    }
}
@media (min-width: 992px) {
    .col-md-15 {
        width: 20%;
        float: left;
    }

    .col-md-30 {
        width: 40%;
        float: left;
    }
}
@media (min-width: 1200px) {
    .col-lg-15 {
        width: 20%;
        float: left;
    }

    .col-lg-30 {
        width: 40%;
        float: left;
    }
}