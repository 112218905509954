.modal-popup {
    &.confirm {
        .action-dismiss {
            background: white;
            padding: 15px 25px;
            border-radius: 0;
            text-transform: uppercase;
            letter-spacing: 1px;
            color: $color-primary;
            border: 1px solid $color-primary;

            -webkit-transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            -ms-transition: all 0.3s ease;
            -o-transition: all 0.3s ease;
            transition: all 0.3s ease;

            &:hover, &:focus {
                background: darken($color-primary, 5%);
                border: 1px solid $color-primary;
                color: white;
            }

            &:visited {
                color: white;
            }
        }

        .action-accept {
            background: $color-secondary;
            padding: 15px 25px;
            border-radius: 0;
            text-transform: uppercase;
            letter-spacing: 1px;
            color: white;
            border: 1px solid $color-secondary;

            -webkit-transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            -ms-transition: all 0.3s ease;
            -o-transition: all 0.3s ease;
            transition: all 0.3s ease;

            &:hover, &:focus {
                background: darken($color-secondary, 5%);
                border: 1px solid darken($color-secondary, 5%);
                color: white;
            }

            &:visited {
                color: white;
            }
        }
    }

    &.tyreselector-modal {
        .modal-content {
            max-height: 400px;
        }
    }
}