.cart-summary {
    background: #f5f5f5;
    padding: 30px 40px;
    > .title {
        font-family: "Lato", sans-serif;
        font-size: 18px;
        line-height: 1;
        font-weight: 700;
        color: $color-text-2;
        margin-bottom: 30px;
    }
    .block {
        > .title {
            font-family: "Lato", sans-serif;
            font-size: 15px;
            line-height: 1;
            color: $color-text-8;
            margin-bottom: 20px;
        }
        &.shipping {
            .fa-angle-down {
                display: inline;
            }
            .fa-angle-up {
                display: none;
            }
            &.active {
                .fa-angle-down {
                    display: none;
                }
                .fa-angle-up {
                    display: inline;
                }
            }
        }
    }

    .table {
        > tbody, > tfoot, > thead {
            > tr {
                > td, > th {
                    border-top: 1px solid $color-text-6;
                    padding: 20px 0;
                }
            }
        }
    }

    .cart-totals {
        tbody, tfoot {
            .mark {
                font-family: "Lato", sans-serif;
                font-size: 15px;
                line-height: 1;
                color: $color-text-8;
                margin-bottom: 20px;
                background: none;
                .label {
                    font-family: "Lato", sans-serif;
                    font-size: 15px;
                    line-height: 1;
                    color: $color-text-8;
                    background: none;
                }
                .value {
                    font-family: "Lato", sans-serif;
                    font-size: 12px;
                    line-height: 1;
                    color: $color-text-3;
                    margin-bottom: 20px;
                    background: none;
                }
            }
            .amount {
                font-family: "Lato", sans-serif;
                font-size: 15px;
                line-height: 1;
                font-weight: 700;
                color: $color-text-8;
                margin-bottom: 20px;
            }
        }
        .grand {
            .amount {
                strong {
                    color: $color-secondary;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .cart-summary {
        padding: 30px 10px;
        .totals {
            tbody {
                > tr:not(:last-child) {
                    > td:last-child {
                        border-top: 1px solid #999 !important;
                    }
                }
            }
        }
    }

    .cart {
        .btn {
            width: 100%;
        }
    }

    .cart-container {
        .form-cart .actions.main > .btn--empty {
            color: $color-primary;
            width: 100%;
        }

        .input-group, .input-group-btn {
            @media screen and (max-width: 767px) {
                display: block;
                width: 100%;
                .btn {
                    width: 100%;
                }
            }
        }
    }
}

/* app/design/frontend/Ilio/saphore/Magento_Checkout/templates/cart/form.phtml */
.cart {
    border: 0;
    thead {
        tr {
            th {
                font-family: "Lato", sans-serif;
                font-size: 14px;
                line-height: 1;
                color: $color-text-3;
                padding-bottom: 20px !important;
                font-weight: 400;
                text-align: center;
                border: 0;
            }
        }
    }
    tbody {
        border-right: 1px solid #ddd;
        border-bottom: 0 !important;
        td {
            padding: 0 10px !important;
            vertical-align: middle !important;
            line-height: 1 !important;
            text-align: center;
            &:first-child {
                width: 100px;
                padding: 0 !important;
                .product-image-wrapper {
                    padding-bottom: 100% !important;
                }
            }

            .action-delete {
                .fa-trash {
                    font-size: 20px;
                }
            }

            .product-item-name {
                min-height: 0 !important;
                margin: 0 !important;
                a {
                    font-family: "Poppins", sans-serif;
                    font-size: 14px;
                    font-weight: 500;
                    color: $color-primary;
                    text-transform: uppercase;
                }
            }
            .product-item-details {
                vertical-align: middle !important;
                padding: 0 !important;
            }

            .price {
                font-family: "Poppins", sans-serif;
                font-size: 14px !important;
                font-weight: 500 !important;
                color: $color-secondary !important;
            }

            .qty {
                .input-text {
                    text-align: center;
                    width: 45px;
                }
            }

            .action {
                color: $color-secondary;
            }
        }

        + tbody {
            border-top: 0;
        }
    }
}
