.toolbar {
    padding-bottom: 30px;

    .toolbar-amount {
        margin: 0;
        text-transform: none;
        font-family: "Poppins", sans-serif;
        font-weight: 400;
        color: $color-text-3;
    }

    .limiter {
        .label, .limiter-label, .limiter-text {
            font-family: "Poppins", sans-serif;
            font-size: 12px;
            line-height: 1;
            font-weight: 400;
            text-transform: uppercase;
            color: $color-text-3;
        }
    }

    label {
        &.sorter-label {
            font-family: "Poppins", sans-serif !important;
            font-size: 12px !important;
            line-height: 1 !important;
            font-weight: 400 !important;
            text-transform: uppercase !important;
            color: $color-text-3 !important;
        }
    }
}