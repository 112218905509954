// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$cart-name: 'cart';
$cart-x: 428px;
$cart-y: 152px;
$cart-offset-x: -428px;
$cart-offset-y: -152px;
$cart-width: 27px;
$cart-height: 26px;
$cart-total-width: 456px;
$cart-total-height: 367px;
$cart-image: 'sprite.png';
$cart: (428px, 152px, -428px, -152px, 27px, 26px, 456px, 367px, 'sprite.png', 'cart', );
$footer-picto-01-name: 'footer-picto-01';
$footer-picto-01-x: 0px;
$footer-picto-01-y: 0px;
$footer-picto-01-offset-x: 0px;
$footer-picto-01-offset-y: 0px;
$footer-picto-01-width: 152px;
$footer-picto-01-height: 152px;
$footer-picto-01-total-width: 456px;
$footer-picto-01-total-height: 367px;
$footer-picto-01-image: 'sprite.png';
$footer-picto-01: (0px, 0px, 0px, 0px, 152px, 152px, 456px, 367px, 'sprite.png', 'footer-picto-01', );
$footer-picto-02-name: 'footer-picto-02';
$footer-picto-02-x: 152px;
$footer-picto-02-y: 0px;
$footer-picto-02-offset-x: -152px;
$footer-picto-02-offset-y: 0px;
$footer-picto-02-width: 152px;
$footer-picto-02-height: 152px;
$footer-picto-02-total-width: 456px;
$footer-picto-02-total-height: 367px;
$footer-picto-02-image: 'sprite.png';
$footer-picto-02: (152px, 0px, -152px, 0px, 152px, 152px, 456px, 367px, 'sprite.png', 'footer-picto-02', );
$footer-picto-03-name: 'footer-picto-03';
$footer-picto-03-x: 0px;
$footer-picto-03-y: 152px;
$footer-picto-03-offset-x: 0px;
$footer-picto-03-offset-y: -152px;
$footer-picto-03-width: 152px;
$footer-picto-03-height: 152px;
$footer-picto-03-total-width: 456px;
$footer-picto-03-total-height: 367px;
$footer-picto-03-image: 'sprite.png';
$footer-picto-03: (0px, 152px, 0px, -152px, 152px, 152px, 456px, 367px, 'sprite.png', 'footer-picto-03', );
$footer-picto-04-name: 'footer-picto-04';
$footer-picto-04-x: 152px;
$footer-picto-04-y: 152px;
$footer-picto-04-offset-x: -152px;
$footer-picto-04-offset-y: -152px;
$footer-picto-04-width: 152px;
$footer-picto-04-height: 152px;
$footer-picto-04-total-width: 456px;
$footer-picto-04-total-height: 367px;
$footer-picto-04-image: 'sprite.png';
$footer-picto-04: (152px, 152px, -152px, -152px, 152px, 152px, 456px, 367px, 'sprite.png', 'footer-picto-04', );
$footer-picto-05-name: 'footer-picto-05';
$footer-picto-05-x: 304px;
$footer-picto-05-y: 0px;
$footer-picto-05-offset-x: -304px;
$footer-picto-05-offset-y: 0px;
$footer-picto-05-width: 152px;
$footer-picto-05-height: 152px;
$footer-picto-05-total-width: 456px;
$footer-picto-05-total-height: 367px;
$footer-picto-05-image: 'sprite.png';
$footer-picto-05: (304px, 0px, -304px, 0px, 152px, 152px, 456px, 367px, 'sprite.png', 'footer-picto-05', );
$footer-unioneuropeenne-name: 'footer-unioneuropeenne';
$footer-unioneuropeenne-x: 304px;
$footer-unioneuropeenne-y: 152px;
$footer-unioneuropeenne-offset-x: -304px;
$footer-unioneuropeenne-offset-y: -152px;
$footer-unioneuropeenne-width: 124px;
$footer-unioneuropeenne-height: 97px;
$footer-unioneuropeenne-total-width: 456px;
$footer-unioneuropeenne-total-height: 367px;
$footer-unioneuropeenne-image: 'sprite.png';
$footer-unioneuropeenne: (304px, 152px, -304px, -152px, 124px, 97px, 456px, 367px, 'sprite.png', 'footer-unioneuropeenne', );
$slider-next-name: 'slider-next';
$slider-next-x: 0px;
$slider-next-y: 304px;
$slider-next-offset-x: 0px;
$slider-next-offset-y: -304px;
$slider-next-width: 63px;
$slider-next-height: 63px;
$slider-next-total-width: 456px;
$slider-next-total-height: 367px;
$slider-next-image: 'sprite.png';
$slider-next: (0px, 304px, 0px, -304px, 63px, 63px, 456px, 367px, 'sprite.png', 'slider-next', );
$slider-prev-name: 'slider-prev';
$slider-prev-x: 63px;
$slider-prev-y: 304px;
$slider-prev-offset-x: -63px;
$slider-prev-offset-y: -304px;
$slider-prev-width: 63px;
$slider-prev-height: 63px;
$slider-prev-total-width: 456px;
$slider-prev-total-height: 367px;
$slider-prev-image: 'sprite.png';
$slider-prev: (63px, 304px, -63px, -304px, 63px, 63px, 456px, 367px, 'sprite.png', 'slider-prev', );
$cart-2x-name: 'cart@2x';
$cart-2x-x: 856px;
$cart-2x-y: 304px;
$cart-2x-offset-x: -856px;
$cart-2x-offset-y: -304px;
$cart-2x-width: 54px;
$cart-2x-height: 52px;
$cart-2x-total-width: 912px;
$cart-2x-total-height: 734px;
$cart-2x-image: 'sprite@2x.png';
$cart-2x: (856px, 304px, -856px, -304px, 54px, 52px, 912px, 734px, 'sprite@2x.png', 'cart@2x', );
$footer-picto-01-2x-name: 'footer-picto-01@2x';
$footer-picto-01-2x-x: 0px;
$footer-picto-01-2x-y: 0px;
$footer-picto-01-2x-offset-x: 0px;
$footer-picto-01-2x-offset-y: 0px;
$footer-picto-01-2x-width: 304px;
$footer-picto-01-2x-height: 304px;
$footer-picto-01-2x-total-width: 912px;
$footer-picto-01-2x-total-height: 734px;
$footer-picto-01-2x-image: 'sprite@2x.png';
$footer-picto-01-2x: (0px, 0px, 0px, 0px, 304px, 304px, 912px, 734px, 'sprite@2x.png', 'footer-picto-01@2x', );
$footer-picto-02-2x-name: 'footer-picto-02@2x';
$footer-picto-02-2x-x: 304px;
$footer-picto-02-2x-y: 0px;
$footer-picto-02-2x-offset-x: -304px;
$footer-picto-02-2x-offset-y: 0px;
$footer-picto-02-2x-width: 304px;
$footer-picto-02-2x-height: 304px;
$footer-picto-02-2x-total-width: 912px;
$footer-picto-02-2x-total-height: 734px;
$footer-picto-02-2x-image: 'sprite@2x.png';
$footer-picto-02-2x: (304px, 0px, -304px, 0px, 304px, 304px, 912px, 734px, 'sprite@2x.png', 'footer-picto-02@2x', );
$footer-picto-03-2x-name: 'footer-picto-03@2x';
$footer-picto-03-2x-x: 0px;
$footer-picto-03-2x-y: 304px;
$footer-picto-03-2x-offset-x: 0px;
$footer-picto-03-2x-offset-y: -304px;
$footer-picto-03-2x-width: 304px;
$footer-picto-03-2x-height: 304px;
$footer-picto-03-2x-total-width: 912px;
$footer-picto-03-2x-total-height: 734px;
$footer-picto-03-2x-image: 'sprite@2x.png';
$footer-picto-03-2x: (0px, 304px, 0px, -304px, 304px, 304px, 912px, 734px, 'sprite@2x.png', 'footer-picto-03@2x', );
$footer-picto-04-2x-name: 'footer-picto-04@2x';
$footer-picto-04-2x-x: 304px;
$footer-picto-04-2x-y: 304px;
$footer-picto-04-2x-offset-x: -304px;
$footer-picto-04-2x-offset-y: -304px;
$footer-picto-04-2x-width: 304px;
$footer-picto-04-2x-height: 304px;
$footer-picto-04-2x-total-width: 912px;
$footer-picto-04-2x-total-height: 734px;
$footer-picto-04-2x-image: 'sprite@2x.png';
$footer-picto-04-2x: (304px, 304px, -304px, -304px, 304px, 304px, 912px, 734px, 'sprite@2x.png', 'footer-picto-04@2x', );
$footer-picto-05-2x-name: 'footer-picto-05@2x';
$footer-picto-05-2x-x: 608px;
$footer-picto-05-2x-y: 0px;
$footer-picto-05-2x-offset-x: -608px;
$footer-picto-05-2x-offset-y: 0px;
$footer-picto-05-2x-width: 304px;
$footer-picto-05-2x-height: 304px;
$footer-picto-05-2x-total-width: 912px;
$footer-picto-05-2x-total-height: 734px;
$footer-picto-05-2x-image: 'sprite@2x.png';
$footer-picto-05-2x: (608px, 0px, -608px, 0px, 304px, 304px, 912px, 734px, 'sprite@2x.png', 'footer-picto-05@2x', );
$footer-unioneuropeenne-2x-name: 'footer-unioneuropeenne@2x';
$footer-unioneuropeenne-2x-x: 608px;
$footer-unioneuropeenne-2x-y: 304px;
$footer-unioneuropeenne-2x-offset-x: -608px;
$footer-unioneuropeenne-2x-offset-y: -304px;
$footer-unioneuropeenne-2x-width: 248px;
$footer-unioneuropeenne-2x-height: 194px;
$footer-unioneuropeenne-2x-total-width: 912px;
$footer-unioneuropeenne-2x-total-height: 734px;
$footer-unioneuropeenne-2x-image: 'sprite@2x.png';
$footer-unioneuropeenne-2x: (608px, 304px, -608px, -304px, 248px, 194px, 912px, 734px, 'sprite@2x.png', 'footer-unioneuropeenne@2x', );
$slider-next-2x-name: 'slider-next@2x';
$slider-next-2x-x: 0px;
$slider-next-2x-y: 608px;
$slider-next-2x-offset-x: 0px;
$slider-next-2x-offset-y: -608px;
$slider-next-2x-width: 126px;
$slider-next-2x-height: 126px;
$slider-next-2x-total-width: 912px;
$slider-next-2x-total-height: 734px;
$slider-next-2x-image: 'sprite@2x.png';
$slider-next-2x: (0px, 608px, 0px, -608px, 126px, 126px, 912px, 734px, 'sprite@2x.png', 'slider-next@2x', );
$slider-prev-2x-name: 'slider-prev@2x';
$slider-prev-2x-x: 126px;
$slider-prev-2x-y: 608px;
$slider-prev-2x-offset-x: -126px;
$slider-prev-2x-offset-y: -608px;
$slider-prev-2x-width: 126px;
$slider-prev-2x-height: 126px;
$slider-prev-2x-total-width: 912px;
$slider-prev-2x-total-height: 734px;
$slider-prev-2x-image: 'sprite@2x.png';
$slider-prev-2x: (126px, 608px, -126px, -608px, 126px, 126px, 912px, 734px, 'sprite@2x.png', 'slider-prev@2x', );
$spritesheet-width: 456px;
$spritesheet-height: 367px;
$spritesheet-image: 'sprite.png';
$spritesheet-sprites: ($cart, $footer-picto-01, $footer-picto-02, $footer-picto-03, $footer-picto-04, $footer-picto-05, $footer-unioneuropeenne, $slider-next, $slider-prev, );
$spritesheet: (456px, 367px, 'sprite.png', $spritesheet-sprites, );
$retina-spritesheet-width: 912px;
$retina-spritesheet-height: 734px;
$retina-spritesheet-image: 'sprite@2x.png';
$retina-spritesheet-sprites: ($cart-2x, $footer-picto-01-2x, $footer-picto-02-2x, $footer-picto-03-2x, $footer-picto-04-2x, $footer-picto-05-2x, $footer-unioneuropeenne-2x, $slider-next-2x, $slider-prev-2x, );
$retina-spritesheet: (912px, 734px, 'sprite@2x.png', $retina-spritesheet-sprites, );

// These "retina group" variables are mappings for the naming and pairing of normal and retina sprites.
//
// The list formatted variables are intended for mixins like `retina-sprite` and `retina-sprites`.
$cart-group-name: 'cart';
$cart-group: ('cart', $cart, $cart-2x, );
$footer-picto-01-group-name: 'footer-picto-01';
$footer-picto-01-group: ('footer-picto-01', $footer-picto-01, $footer-picto-01-2x, );
$footer-picto-02-group-name: 'footer-picto-02';
$footer-picto-02-group: ('footer-picto-02', $footer-picto-02, $footer-picto-02-2x, );
$footer-picto-03-group-name: 'footer-picto-03';
$footer-picto-03-group: ('footer-picto-03', $footer-picto-03, $footer-picto-03-2x, );
$footer-picto-04-group-name: 'footer-picto-04';
$footer-picto-04-group: ('footer-picto-04', $footer-picto-04, $footer-picto-04-2x, );
$footer-picto-05-group-name: 'footer-picto-05';
$footer-picto-05-group: ('footer-picto-05', $footer-picto-05, $footer-picto-05-2x, );
$footer-unioneuropeenne-group-name: 'footer-unioneuropeenne';
$footer-unioneuropeenne-group: ('footer-unioneuropeenne', $footer-unioneuropeenne, $footer-unioneuropeenne-2x, );
$slider-next-group-name: 'slider-next';
$slider-next-group: ('slider-next', $slider-next, $slider-next-2x, );
$slider-prev-group-name: 'slider-prev';
$slider-prev-group: ('slider-prev', $slider-prev, $slider-prev-2x, );
$retina-groups: ($cart-group, $footer-picto-01-group, $footer-picto-02-group, $footer-picto-03-group, $footer-picto-04-group, $footer-picto-05-group, $footer-unioneuropeenne-group, $slider-next-group, $slider-prev-group, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `retina-sprite` mixin sets up rules and a media query for a sprite/retina sprite.
//   It should be used with a "retina group" variable.
//
// The media query is from CSS Tricks: https://css-tricks.com/snippets/css/retina-display-media-query/
//
// $icon-home-group: ('icon-home', $icon-home, $icon-home-2x, );
//
// .icon-home {
//   @include retina-sprite($icon-home-group);
// }
@mixin sprite-background-size($sprite) {
  $sprite-total-width: nth($sprite, 7);
  $sprite-total-height: nth($sprite, 8);
  background-size: $sprite-total-width $sprite-total-height;
}

@mixin retina-sprite($retina-group) {
  $normal-sprite: nth($retina-group, 2);
  $retina-sprite: nth($retina-group, 3);
  @include sprite($normal-sprite);

  @media (-webkit-min-device-pixel-ratio: 2),
         (min-resolution: 192dpi) {
    @include sprite-image($retina-sprite);
    @include sprite-background-size($normal-sprite);
  }
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}

// The `retina-sprites` mixin generates a CSS rule and media query for retina groups
//   This yields the same output as CSS retina template but can be overridden in SCSS
//
// @include retina-sprites($retina-groups);
@mixin retina-sprites($retina-groups) {
  @each $retina-group in $retina-groups {
    $sprite-name: nth($retina-group, 1);
    .#{$sprite-name} {
      @include retina-sprite($retina-group);
    }
  }
}
