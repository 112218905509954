.slider-product {
    text-align: center;

    @include element('content') {
        margin-bottom: 20px;
    }

    @include element('image') {
        width: 100%;
        height: 180px;
        border: 1px solid $color-border-2;
        margin-bottom: 30px;
        overflow: hidden;
        position: relative;
        img {
            margin: 0 auto;
        }
    }

    @include element('title') {
        font-family: "Poppins", sans-serif;
        font-size: 16px;
        font-weight: 400;
        text-transform: uppercase;
        color: $color-text-9;
        margin-top: 0;
        margin-bottom: 10px;

        &:after {
            content: '';
            display: block;
            width: 8px;
            height: 1px;
            background: $color-text-3;
            text-align: center;
            margin: 6px auto 0 auto;
        }
    }

    @include element('price') {
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
        color: $color-five;
        margin-bottom: 20px;

        .old-price {
            color: $color-text-4;
            text-decoration: line-through;
            display: inline;
        }

        .final-price {
            color: $color-five;
            display: inline;
        }

        .price-box {
            display: inline;
            .price-container {
                .price {
                    font-size: 14px;
                }
            }
        }
    }

    @include element('notice') {
        position: absolute;
        top: 0;
        left: 0;
        display: inline-block;
        padding: 8px 15px;
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        line-height: 1;
        font-weight: 400;
        color: white;
        text-transform: uppercase;
        z-index: 1;
        background: $color-primary;

        @include modifier('new') {
            background: #bd2f48;
        }

        @include modifier('promo') {
            background: #ffd200;
            color: $color-bg-1;
        }
    }
}