/* Magento_Checkout::progress-bar.html */
.opc-progress-bar {
    position: relative;
    text-align: center;

    &:before {
        content: '';
        width: 100%;
        height: 1px;
        background: #dbdbdb;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
    }
    .opc-progress-bar-item {
        width: 370px;
        max-width: 40%;

        &:before {
            display: none;
        }

        &:not(:last-child) {
            margin-right: 30px;
        }

        &._active {
            > span {
                background: $color-primary;
                color: white;
            }
        }

        > span {
            background: white;
            padding: 15px 25px;
            border-radius: 0;

            font-family: "Poppins", sans-serif;
            font-size: 14px;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 1px;
            color: $color-primary;
            border: 1px solid $color-primary;

            &:before, &:after {
                display: none;
            }
        }
    }
}

/* Ilio_Theme::shipping.html */
.opc-wrapper {
    .step-title {
        font-family: "Poppins", sans-serif;
        font-size: 19px;
        font-weight: 600;
        color: $color-primary;
        text-transform: uppercase;

        border: none;
    }

    .shipping-address-item {
        &.selected-item {
            border-color: $color-primary;
            &:after {
                background-color: $color-primary;
            }
        }

        font-family: "Poppins", sans-serif;
        font-size: 14px;
        font-weight: 400;
        color: $color-text-1;
    }
}

.table-checkout-shipping-method {
    width: 100%;
    margin-bottom: 20px;
    tbody {
        td {
            border: none;
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }
}

.opc-block-summary {
    > .title {
        font-family: "Lato", sans-serif;
        font-size: 18px;
        font-weight: 700;
        color: $color-text-2;
        border: none;
    }

    .items-in-cart {
        > .title {
            strong {
                font-family: "Lato", sans-serif;
                font-size: 15px !important;
                font-weight: 700;
                color: $color-text-8;
            }
        }
    }

    .table-totals {
        .mark, th, td {
            font-family: "Lato", sans-serif;
            font-size: 15px !important;
            font-weight: 400;
            color: $color-text-8;
            padding: 5px 0;
            .label {
                font-family: "Lato", sans-serif;
                font-size: 15px !important;
                font-weight: 400;
                color: $color-text-8;
                padding: 5px 0;
            }

            .value {
                font-family: "Lato", sans-serif;
                font-size: 10px !important;
                font-weight: 400;
                color: $color-text-3;
                padding: 5px 0;
            }
        }
        .amount, td.grandtotal {
            font-family: "Lato", sans-serif;
            font-size: 15px !important;
            font-weight: 700;
            color: $color-text-8;
            padding: 5px 0;
            text-align: right;
        }
    }
}

.opc-block-shipping-information {
    padding: 0;
    > .shipping-information {
        padding: 30px;
        border: 1px solid #dbdbdb;
    }
    .shipping-information-title {
        font-family: "Lato", sans-serif;
        font-size: 18px;
        font-weight: 700;
        color: $color-text-2;
        border: none;
        padding: 0;
        margin-bottom: 12px;
    }

    .shipping-information-content {
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        font-weight: 400;
        color: $color-text-1;
    }
}

.checkout-payment-method {
    .payment-method-title {
        .radio {
            float: left;
            margin-right: 10px;
        }
    }

    .payment-option-content {
        padding: 0;
    }

    .payment-method-content {
        > .actions-toolbar {
            > .primary {
                .action.primary {
                    font-size: 14px;
                    background: $color-primary;
                    padding: 15px 50px;
                    border-radius: 0;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    color: white;
                    border: 1px solid $color-primary;
                    -webkit-transition: all 0.3s ease;
                    -moz-transition: all 0.3s ease;
                    -ms-transition: all 0.3s ease;
                    -o-transition: all 0.3s ease;
                    transition: all 0.3s ease;
                    background: $color-secondary;
                    border-color: $color-secondary;
                    &:hover, &:focus {
                        background: darken($color-secondary, 5%);
                        border: 1px solid darken($color-secondary, 5%);
                        color: white;
                    }
                }
            }
        }
    }
}

.minicart-items {
    .product-item-details {
        .price {
            color: $color-secondary;
        }
    }
}

.checkout-success {
    .btn {
        margin-top: 20px;
    }
}

.discount-title {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: $color-text-1;
}

.checkout-index-index {
    .opc-block-summary {
        .table-totals {
            tr.totals-tax {
                display: none !important;
            }
            //display: none !important;
        }
    }
}