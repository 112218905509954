.my-contact {
    overflow: hidden;
    padding-bottom: 40px;
    margin-bottom: 40px;
    border-bottom: 1px solid $color-border-1;

    @include element('title') {
        font-size: 18px;
        line-height: 1;
        font-weight: 700;
        margin-bottom: 18px;
    }

    @include element('name') {
        font-size: 15px;
        color: $color-text-3;
    }

    @include element('mail') {
        font-size: 15px;
        color: $color-text-3;
    }

    .btn {
        float: right;

        &.visible-xs {
            float: none;
            width: 100%;
            margin-top: 10px;
        }
    }
}