.cms-block {
    @include element('container') {
        h2 {
            font-family: "Poppins", sans-serif;
            font-size: 48px;
            line-height: 1;
            color: white;
            font-weight: 400;
            text-transform: uppercase;
            letter-spacing: 2px;
            margin-bottom: 30px;
            @media screen and (max-width: 991px) {
                font-size: 28px;
            }
        }

        p {
            font-family: "Poppins", sans-serif;
            font-size: 16px;
            line-height: 2;
            color: white;
            font-weight: 300;
        }

        .btn--all {
            color: white;
            border-color: white;
            font-weight: 300;
            margin-top: 0;
            &:hover, &:focus {
                color: $color-three;
                border-color: $color-three;
            }
        }
    }

    @include element('column') {
        text-align: center;
        @include modifier('blue') {
            background: $color-primary;
            display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
            display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
            display: -ms-flexbox; /* TWEENER - IE 10 */
            display: -webkit-flex;
            display: flex;
            -webkit-box-align: center;
            -webkit-flex-align: center;
            -ms-flex-align: center;
            -webkit-align-items: center;
            align-items: center;
            justify-content: center;
            padding: 50px;

            @media screen and (max-width: 991px) {
                padding: 50px;
                display: block;
            }

            @media screen and (max-width: 767px) {
                padding: 15px;
            }
        }

        @include modifier('image') {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;

            margin-bottom: 20px;

            -webkit-transition: background 0.3s ease;
            -moz-transition: background 0.3s ease;
            -ms-transition: background 0.3s ease;
            -o-transition: background 0.3s ease;
            transition: background 0.3s ease;
            img {
                opacity: 0;
                visibility: hidden;
            }

            a {
                display: block;
            }
        }
    }
}

#cms-block-video {
    display: none;
    &.embed-responsive-16by9 {
        padding-bottom: 56.25% !important;
    }
}