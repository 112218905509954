@media (min-width: 992px) {
    .abs-shopping-cart-items-desktop,
    .block-cart-failed,
    .cart-container .cart-gift-item,
    .cart-container .form-cart {
        width: calc(100% - 355px) !important;
    }

    .cart-container {
        .form-cart {
            padding-left: 0;
        }
    }

    .cart-summary {
        width: 305px !important;
    }
}

@media (max-width: 991px) {
    .abs-shopping-cart-items-desktop,
    .block-cart-failed,
    .cart-container .cart-gift-item,
    .cart-container .form-cart {
        width: 100% !important;
    }

    .cart-summary {
        width: 100% !important;
    }

    .cart-container {
        .form-cart {
            padding-left: 0;
        }
    }

    .cart-discount {
        width: 100%;
        padding-left: 0;
    }
}

@media (min-width: 768px) {
    .cart-discount, #discount-form {
        width: 570px;
        padding-left: 0;
        max-width: 100%;
        input {
            width: calc(100% - 30px);
            height: 52px !important;
        }
        .discount-title {
            font-family: "Poppins", sans-serif;
            font-size: 14px;
            font-weight: 600;
            color: $color-text-1;
        }
    }
}

@media (max-width: 767px) {
    #co-payment-form {
        .fieldset {
            display: block;
            width: 100%;
            padding: 0 15px;
        }
    }

    .cart-discount, #discount-form {
        .input-group, .input-group-btn {
            @media screen and (max-width: 767px) {
                display: block;
                width: 100%;
                .btn {
                    margin-left: 0;
                }
            }
        }
    }
}