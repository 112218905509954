/* Checkbox */
$inputHeight: 18px;
$inputWidth: 18px;
$inputBorderWidth: 2px;
$inputActiveScale: 4px; // optimized for even numbers

.s-checkbox {
    input[type="checkbox"],
    input[type="radio"] {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;

        + label {
            &:before {
                border-color: $color-primary;
                background-color: lighten(#cccccc, 15%);
            }
        }

        &:active {
            + label {
                &:before {
                    transition-duration: 0s;
                }
            }

        }

        + label {
            position: relative;
            padding-left: 2em;
            vertical-align: middle;
            user-select: none;
            cursor: pointer;
            font-weight: 400;

            font-size: 15px;
            font-family: "Lato", sans-serif;
            color: $color-text-3;

            &:before {
                box-sizing: content-box;
                content: '';
                color: $color-primary;
                position: absolute;
                top: 50%;
                left: 0;
                width: $inputWidth;
                height: $inputHeight;
                margin-top: -($inputHeight/2 + $inputBorderWidth);
                border: $inputBorderWidth solid #cccccc;
                text-align: center;
                transition: all 0.4s ease;
            }
            &:after {
                box-sizing: content-box;
                content: '';
                background-color: $color-primary;
                position: absolute;
                top: 50%;
                left: $inputBorderWidth + $inputActiveScale/2;
                width: $inputWidth - $inputActiveScale;
                height: $inputHeight - $inputActiveScale;
                margin-top: -(($inputHeight - $inputActiveScale)/2);
                transform: scale(0);
                transform-origin: 50%;
                transition: transform 200ms ease-out;
            }
        }
    }

    input[type="checkbox"] {
        + label {
            &:after {
                background-color: transparent;
                top: 50%;
                left: 4px;
                width: 13px;
                height: 4px;
                margin-top: -4px;
                border-style: solid;
                border-color: $color-primary;
                border-width: 0 0 2px 2px;
                border-image: none;
                transform: rotate(-45deg) scale(0);
            }
        }
        &:checked {
            + label {
                &:after {
                    content: ''; //\2713';
                    transform: rotate(-45deg) scale(1);
                    transition: transform 200ms ease-out;
                }
            }

        }
    }

    input[type="checkbox"] {
        &:checked {
            + label {
                &:before {
                    animation: borderscale 200ms ease-in;
                    background: white;
                }
                &:after {
                    transform: rotate(-45deg) scale(1);
                }
            }
        }
    }
}

@keyframes borderscale {
    50% {
        box-shadow: 0 0 0 2px $color-primary;
    }
}

.s-form {
    .form-group {
        margin: 0;
    }

    .note {
        font-family: "Lato", sans-serif;
        font-size: 15px;
    }

    .control {
        margin-bottom: 20px;
    }

    input[type="text"],
    input[type="password"],
    input[type="url"],
    input[type="tel"],
    input[type="search"],
    input[type="number"],
    input[type="datetime"],
    input[type="email"] {
        height: 50px;
        border-color: $color-text-5;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        font-family: "Lato", sans-serif;
        padding: 0 20px;
        color: $color-text-1;
        font-size: 15px;
        &.mage-error {
            border-color: #ed8380;
        }
    }

    .label {
        display: block;
        text-align: left;
        font-family: "Lato", sans-serif !important;
        font-size: 15px !important;
        color: $color-text-3 !important;
        margin-bottom: 10px;
    }

    .legend {
        font-family: "Poppins", sans-serif;
        font-size: 24px;
        text-align: center;
        font-weight: 500;
        text-transform: uppercase;
        color: $color-text-2;
    }

    .back {
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        line-height: 1;
        font-weight: 500;
        color: $color-five;
        text-decoration: none !important;
        margin-top: 20px;
        padding-bottom: 4px;
        border-bottom: 1px solid $color-five;
        text-transform: uppercase;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;

        &:hover {
            text-decoration: none;
            color: $color-secondary;
            border-color: $color-secondary;
        }
    }

    @include element('notice') {
        text-align: right;
        font-family: "Lato", sans-serif;
        font-size: 14px;
        font-style: italic;
        color: $color-text-3;
        margin-top: 10px;
    }

    @include element('rgpd-notice') {
        font-family: "Lato", sans-serif;
        font-size: 14px;
        font-style: italic;
        color: $color-text-3;
        margin-top: -20px;
    }

    select {
        background: url(../images/icn_arrow_select.png) no-repeat 0 0 #ffffff;
        background-position: right 30px center;
        box-sizing: border-box;
        color: $color-text-1;
        display: block;
        font-size: 15px;
        font-weight: normal;
        padding: 0 45px 0 20px;
        position: relative;
        margin: 0;

        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        width: 100%;
        height: 50px;
        border: 1px solid $color-text-5;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        &.mage-error {
            border-color: #ed8380;
        }
        &.mbottom {
            margin-bottom: 20px;
        }
    }

    // Internet explorer
    select::-ms-expand {
        display: none;
    }

    &.form-create-account {
        .actions-toolbar {
            > .primary {
                float: none !important;
            }
        }
        .pull-right {
            margin-top: 30px;
            float: none !important;
            .btn {
                width: 100%;
            }
        }
    }

    &.forget {
        .input-group, .input-group-btn {
            @media screen and (max-width: 767px) {
                display: block;
                width: 100%;
                input {
                    width: 100%;
                }
            }
        }

        .btn--large {
            padding: 15px 0 !important;
            width: 100%;
        }
    }
}

.login-form-top {
    h2 {
        font-style: normal;
        text-align: center;
    }

    .btn {
        color: white !important;
        font-style: normal !important;
    }

    @media screen and (max-width: 991px) {
        display: none;
    }
}