.hotnews {
    @include element('img') {
        position: relative;
        overflow: hidden;
        padding-bottom: 100%;

        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;

        margin-bottom: 20px;
        img {
            position: absolute;
            opacity: 0;
            visibility: hidden;
        }
    }

    @include element('title') {
        font-family: "Lato", sans-serif;
        font-size: 18px;
        font-weight: 700;
        color: $color-text-2;

        margin-top: 0;
        margin-bottom: 30px;
    }

    @include element('date') {
        font-family: "Lato", sans-serif;
        font-size: 12px;
        text-transform: uppercase;
        color: $color-text-1;
        margin-bottom: 26px;
    }

    @include element('content') {
        font-family: "Lato", sans-serif;
        font-size: 15px;
        line-height: 1.6;
        color: $color-text-3;
        margin-bottom: 50px;
        p, ul, li {
            font-family: "Lato", sans-serif;
            font-size: 15px;
            line-height: 1.6;
            color: $color-text-3;
            margin-bottom: 50px;
        }
    }

    @include element('item') {
        &:not(:last-child) {
            border-bottom: 1px solid $color-border-1;
            margin-bottom: 50px;
        }
    }
}