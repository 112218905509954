@import url('https://fonts.googleapis.com/css?family=Lato:300,400,400i,700,700i,900');
@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,700');

// Import helpers
@import "helpers/mixins";
@import "helpers/variables";
@import "helpers/typography";
@import "helpers/sprite";

// Import website components (ie blocks)
@import "components/loader";
@import "components/form";
@import "components/button";
@import "components/header";
@import "components/minicart";
@import "components/breadcrumb";
@import "components/pagination";
@import "components/newsletter";
@import "components/footer";
@import "components/collapse";
@import "components/documentation";
@import "components/article";
@import "components/dashboard";
@import "components/hotnews";
@import "components/cart";
@import "components/info-block";
@import "components/slider";
@import "components/slider-product";
@import "components/product";
@import "components/cms";
@import "components/cms-block";
@import "components/domain";
@import "components/tyreselector";
@import "components/return";
@import "components/related";
@import "components/category";
@import "components/toolbar";
@import "components/checkout";
@import "components/order";
@import "components/contact";
@import "components/modal";

// Import website layouts (ie pages layouts)
@import "layouts/default";
@import "layouts/form";
@import "layouts/cart";
@import "layouts/home";
@import "layouts/product";
@import "layouts/checkout";
@import "layouts/dashboard";
@import "layouts/degraded";