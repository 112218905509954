.doc {
    @include element('categories') {
        background: $color-border-1;
        padding: 50px;
        margin-top: 25px;
        ul {
            li {
                a {
                    display: block;
                    background: white;
                    font-size: 16px;
                    font-family: "Poppins", sans-serif;
                    font-weight: 500;
                    text-transform: uppercase;
                    text-align: center;
                    padding: 10px;
                    margin-bottom: 15px;
                    border-right: 15px solid $color-border-1;
                    color: $color-text-3;
                    text-decoration: none;
                    -webkit-transition: all 0.3s ease;
                    -moz-transition: all 0.3s ease;
                    -ms-transition: all 0.3s ease;
                    -o-transition: all 0.3s ease;
                    transition: all 0.3s ease;
                    &:hover, &.doc__toggler--active {
                        background: $color-secondary;
                        color: white;
                        text-decoration: none;
                        border-color: $color-secondary;
                        margin-left: 15px;
                    }
                }

                &:last-child {
                    margin-bottom: 0;
                    a {
                        margin-bottom: 0;
                    }
                }
            }
        }

        @media (max-width: 991px) {
            padding: 20px;
            ul {
                li {
                    a {
                        border-right: 0;
                        &:hover, &.doc__toggler--active {
                            margin-left: 0;
                        }
                    }
                }
            }
        }
    }

    @include element('category') {
        @include modifier('hide') {
            display: none;
        }

        ul {
            li {
                a {
                    font-style: normal;
                    font-family: "Lato", sans-serif;
                    font-size: 15px;
                    display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
                    display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
                    display: -ms-flexbox; /* TWEENER - IE 10 */
                    display: -webkit-flex;
                    display: flex;
                    -webkit-box-align: center;
                    -webkit-flex-align: center;
                    -ms-flex-align: center;
                    -webkit-align-items: center;
                    align-items: center;
                    color: $color-text-2;
                    .doc__link {
                        font-size: 13px;
                        font-weight: 700;
                        color: $color-primary;
                        text-transform: uppercase;
                        margin-left: 20px;
                        display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
                        display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
                        display: -ms-flexbox; /* TWEENER - IE 10 */
                        display: -webkit-flex;
                        display: flex;
                        -webkit-box-align: center;
                        -webkit-flex-align: center;
                        -ms-flex-align: center;
                        -webkit-align-items: center;
                        align-items: center;
                        justify-content: center;
                        i {
                            font-size: 21px;
                            margin-right: 5px;
                        }
                    }

                    &:hover, &:focus {
                        text-decoration: none;
                        color: $color-primary;
                    }
                }

                &:before {
                    content: '-';
                    margin-right: 5px;
                    float: left;
                }
            }
        }

        @media (max-width: 767px) {
            ul {
                li {
                    a {
                        display: block;
                        padding-bottom: 15px;
                        border-bottom: 1px solid #ddd;
                        .doc__link {
                            display: block;
                            margin-left: 0;
                            margin-top: 10px;
                        }
                    }
                }
            }
        }
    }
}