.cms-index-index {
    background-image: url('../images/home-bg.jpg');
    background-repeat: no-repeat;
    background-position: top center;

    // Header
    .ves-menutop {
        padding-left: 15px;
        padding-right: 15px;
        margin-left: auto;
        margin-right: auto;
        max-width: 1200px;
        box-sizing: border-box;
        width: 100%;
        -webkit-flex-grow: 1;
        flex-grow: 1;
        -webkit-flex-shrink: 0;
        flex-shrink: 0;
        -webkit-flex-basis: auto;
        flex-basis: auto;
    }

    div.page-header .ves-header1 .header-content {
        padding:0;
    }
    // .ves-inner {
    //     > .header-content {
    //         background: white;
    //     }
    // }

    div.page-header.navbar-fixed-top #ves-header {
        background: none;
    }

    .navbar-fixed-top #ves-header {
        box-shadow: none;
    }

    // Tyreselector
    #tyreselector {
        margin-top: 80px;
        margin-bottom: 100px;
    }

    @media screen and (min-width: 1900px) {
        background-size: 100%;
    }
}

.homepage {
    overflow: hidden;
}

@media screen and (min-width: 1200px) {
    .cms-index-index {
        .style-home3 {
            .col-danger {
                /* Override home slider */
                .blog-posts {
                    .owl-item {
                        &.active {
                            &:nth-child(3n) {
                                bottom: 30px;
                            }
                        }
                    }
                    // Only 2 items
                    &#blog-posts--2 {
                        .owl-item {
                            &.active {
                                width: 398px !important;
                                .ves-post {
                                    &.post-item {
                                        float: none;

                                        .post-image {
                                            float: none;
                                            width: initial;
                                        }

                                        .post-meta {
                                            float: left;
                                            padding: 0;
                                        }
                                    }
                                }
                                &:first-child {
                                    .ves-post {
                                        &.post-item {
                                            .post-meta {
                                                width: initial;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1199px) {
    .cms-index-index {
        background-size: auto 100vh;
    }
}

@media screen and (min-width: 992px) {
    .cms-index-index {
        #ves-header {
            background: none;
        }
    }
}

@media screen and (max-width: 767px) {
    .cms-index-index {
        .ves-post.post-item {
            .post-meta {

            }
        }
    }
}