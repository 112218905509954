/* Override theme header */
@media screen and (min-width: 992px) {
    .page-wrapper {
        padding-top: 300px;
        -webkit-transition: all 0.75s ease;
        -moz-transition: all 0.75s ease;
        -ms-transition: all 0.75s ease;
        -o-transition: all 0.75s ease;
        transition: all 0.75s ease;
    }

    // #ves-header {
    //     background: white;
    //     > .ves-inner {
    //         height: 195px;
    //     }
    // }

    body.on-scroll {
        #ves-header {
            > .ves-inner {
                height: 83px;
            }
        }
    }

    div.page-header {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 100;
        .ves-header1 {
            background: none;
            .header-content {
                padding-top: 0;
            }

            .ves-megamenu {
                background: $color-primary;

                li {
                    &.level0 {
                        > a {
                            font-size: 18px;
                        }
                    }
                }
            }

            .header-links-wrapper {
                .header-links {
                    font-size: 14px;
                    font-family: "Lato", sans-serif;
                    background: $color-secondary;
                    color: white;
                    i {
                        color: white;
                    }
                    .options {
                        .action.toggle {
                            &:after {
                                font-size: 10px;
                                line-height: 22px;
                                margin-left: 8px;
                            }
                        }
                    }
                }
            }

            .search-wrapper {
                background: $color-four;
                font-size: 14px;
                font-family: "Lato", sans-serif;
                color: white;
                i {
                    color: white;
                }

                input {
                    font-size: 14px;
                    font-family: "Lato", sans-serif;
                    background: none;
                    color: white;
                    opacity: 1 !important;
                    font-style: normal;
                    font-weight: 400;
                    @include placeholder() {
                        font-size: 14px;
                        font-family: "Lato", sans-serif;
                        background: none;
                        color: white;
                        opacity: 1 !important;
                        font-style: normal;
                        font-weight: 400;
                    }
                }
            }

            .setting-links {
                font-size: 14px;
                font-family: "Lato", sans-serif;
                background: $color-three;
                color: white;
                text-transform: uppercase;
                i {
                    color: white;
                }
                .options {
                    .action.toggle {
                        &:after {
                            font-size: 10px;
                            line-height: 22px;
                            margin-left: 8px;
                        }
                    }
                }
            }
            .minicart-wrapper {
                position: static !important;
                .action.showcart {
                    margin-top: 0;
                }
            }
        }

        .header-links, .setting-links {
            .options.active {
                overflow: initial;
            }
        }

        .header__top {
            margin-top: 10px;
        }

        &.navbar-fixed-top {
            #ves-header {
                background: white;
                box-shadow: none;
            }

            .header-content {
                padding-bottom: 18px !important;
            }

            .header__top,
            .h-infos__opening,
            .h-infos__contact {
                position: absolute !important;
                top: -9999px !important;
                left: -9999px !important;
                -webkit-transition: all 0.75s ease;
                -moz-transition: all 0.75s ease;
                -ms-transition: all 0.75s ease;
                -o-transition: all 0.75s ease;
                transition: all 0.75s ease;
            }

            .header__bottom {
                padding-top: 20px;
            }
            &.page-header {
                .ves-header1 {
                    .logo-wrapper {
                        text-align: left;
                    }
                }
            }

            .header__bottom-customer {
                display: block;
                .header-links {
                    padding-top: 9px;
                    .switcher-trigger {
                        font-size: 18px !important;
                        text-transform: uppercase !important;
                        color: $color-secondary !important;

                        &:after {
                            vertical-align: middle;
                        }
                    }
                    .fa {
                        color: $color-secondary;
                    }
                }
            }
        }
    }

    .store-language {
        color: $color-text-1;
        text-transform: none;
        .selected {
            font-style: normal;
        }
    }

    .header {
        @include element('bottom') {
            background-color: white;
            padding-top: 40px;
            padding-bottom: 30px;
            display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
            display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
            display: -ms-flexbox; /* TWEENER - IE 10 */
            display: -webkit-flex;
            display: flex;
            -webkit-box-align: center;
            -webkit-flex-align: center;
            -ms-flex-align: center;
            -webkit-align-items: center;
            align-items: center;
            justify-content: center;
        }

        @include element('infos') {
            display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
            display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
            display: -ms-flexbox; /* TWEENER - IE 10 */
            display: -webkit-flex;
            display: flex;
            -webkit-box-align: center;
            -webkit-flex-align: center;
            -ms-flex-align: center;
            -webkit-align-items: center;
            align-items: center;
            justify-content: center;
        }

        @include element('bottom-customer') {
            display: none;
        }
    }
}

.minicart-wrapper {
    .fa-shopping-cart {
        &:before {
            content: '';
            display: block;
            @include retina-sprite($cart-group);
        }
    }
    .action.showcart {
        .cart-title {
            font-size: 23px;
            line-height: 1;
            text-transform: uppercase;
            font-style: normal;
            color: $color-text-3;
        }
    }
}

.h-infos {
    @include element('opening') {
        display: inline-block;
        font-size: 18px;
        line-height: 1;
        font-family: "Lato", sans-serif;
        color: $color-text-6;
        text-transform: uppercase;
        padding-right: 20px;
        @media screen and (min-width: 992px) and (max-width: 1050px) {
            font-size: 14px;
        }
    }

    @include element('hours') {
        display: inline-block;
        padding: 0 15px 0 20px;
        border-left: 1px solid $color-text-5;
        border-right: 1px solid $color-text-5;
    }

    @include element('contact') {
        font-size: 23px;
        line-height: 1;
        font-family: "Poppins", sans-serif;
        color: $color-text-3;
        text-transform: uppercase;
        letter-spacing: 2px;
        @media screen and (min-width: 992px) and (max-width: 1050px) {
            font-size: 20px;
        }
    }

    @include element('phone') {
        font-size: 24px;
        line-height: 1;
        font-family: "Lato", sans-serif;
        font-weight: 900;
        color: $color-secondary;
        a {
            font-size: 24px;
            line-height: 1;
            font-family: "Lato", sans-serif;
            font-weight: 900;
            color: $color-secondary;
        }
        i {
            font-size: 22px !important;
            margin-right: 5px;
            line-height: 1;
            color: $color-secondary !important;
            @media screen and (min-width: 992px) and (max-width: 1050px) {
                font-size: 20px;
            }
        }
        @media screen and (min-width: 992px) and (max-width: 1050px) {
            font-size: 20px;
        }
    }
}

.logo {
    img {
        max-width: 200px;
    }
}

@media screen and (max-width: 991px) {
    .page-header {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 1;
        .ves-header1 {
            background: white !important;
        }

        &.navbar-fixed-top {
            .ves-header1 {
                .minicart-wrapper {
                    .action.showcart {
                        margin-top: 0;
                    }
                }
            }
        }

        .header-links {
            .options {
                li {
                    a {
                        color: $color-primary;
                    }
                }
            }
        }
    }

    .page-wrapper {
        padding-top: 150px;
        -webkit-transition: all 0.75s ease;
        -moz-transition: all 0.75s ease;
        -ms-transition: all 0.75s ease;
        -o-transition: all 0.75s ease;
        transition: all 0.75s ease;
    }

    .ves-menutop {
        .nav-sections-item-title {
            a {
                &:hover {
                    color: $color-secondary;
                }
            }
        }
    }
}