// Product single
.product-info-main {
    .page-title-wrapper {
        .page-title {
            font-size: 18px;
            text-align: left;
            letter-spacing: 0;
        }

        .overview {
            margin-bottom: 0 !important;
            .type {
                display: none;
            }

            .value {
                font-family: "Poppins", sans-serif;
                font-size: 14px;
                font-weight: 600;
                color: $color-primary;
                text-transform: uppercase;
                line-height: 1;
                margin-top: 0;
                margin-bottom: 0;
            }
        }
    }

    .product-info-price {
        font-family: "Poppins", sans-serif;
        font-size: 16px;
        font-weight: 600;
        color: $color-secondary;
        .stock {
            font-weight: 400;
        }
    }

    .stock {
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        color: $color-text-1;
        text-transform: lowercase;
        position: relative;
        padding-left: 14px;
        &:before {
            content: '';
            display: block;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: #78c316;

            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
        }

        &.unavailable {
            &:before {
                background: red;
            }
        }
    }

    .price-box {
        margin-bottom: 40px;
        .weee {
            .price {
                font-family: "Poppins", sans-serif;
                font-size: 18px;
            }
        }
    }

    .product-add-form {
        float: none;
    }

    .box-tocart {
        margin-bottom: 15px;
        .field {
            &.qty {
                label {
                    font-family: "Poppins", sans-serif;
                    font-size: 14px;
                    color: $color-text-1;
                    text-transform: none;
                    font-weight: 400 !important;
                }
            }
        }
    }

    .product-list-categories {
        margin-top: 25px;
        span {
            font-family: "Poppins", sans-serif;
            font-size: 12px;
            color: $color-text-3;
            display: block;
            float: none;
        }

        a {
            font-family: "Poppins", sans-serif;
            font-size: 12px;
            color: $color-text-3;
            font-style: italic;
        }
    }
}

// Product single - Details
.product {
    &.info.detailed {
        border-top: 0;
    }

    .icon {
        > span {
            border: none;
            span {
                font-family: "Poppins", sans-serif;
                font-size: 12px;
                line-height: 1;
                text-transform: uppercase;
                color: white;
                font-weight: 600;
                background: #bd2f48;
                padding: 6px 10px;
            }
        }
    }
}

// Product View layout
.p-column {
    @include modifier('left') {
        padding-right: 55px;
        border-right: 1px solid #cecece;
    }

    @include modifier('right') {
        padding-left: 55px;
    }

    @media screen and (max-width: 991px) {
        @include modifier('left') {
            padding-right: 15px;
            border-right: none;
        }

        @include modifier('right') {
            padding-left: 15px;
        }
    }

    @media screen and (max-width: 767px) {
        @include modifier('left') {
            width: 100%;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-flex-direction: column;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-flex-grow: 1;
            flex-grow: 1;
            -webkit-flex-basis: 100%;
            flex-basis: 100%;
            -ms-flex-order: 1;
            -webkit-order: 1;
            order: 1;
        }
    }
}

// Product Listing layout
.l-column {
    @include modifier('left') {
        padding-right: 0;
    }

    @include modifier('right') {
        padding-left: 55px;
    }

    @media screen and (max-width: 991px) {
        @include modifier('left') {
            padding-right: 15px;
        }

        @include modifier('right') {
            padding-left: 15px;
            border-left: none;
        }
    }
}

// Product listing
.category-tyreselector {
    margin-bottom: 20px;
    #tyreselector {
        background: #ededed;
    }

    .tyre-sel {
        @include element('container') {
            background: #ededed;
            position: relative;
        }
        @include element('cat') {
            label {
                background: #ededed;
                &:hover {
                    background: $color-primary;
                }
            }
        }
        @include element('fields') {
            input[type=text] {
                background: url(../images/tyre-search-icon.png) right 9px center no-repeat #ededed;
            }

            select {
                background: url(../images/icn_arrow_select.png) right 9px center no-repeat #ededed;
            }
        }
    }
}

.category-catalog {
    margin-bottom: 30px;
}

.catalogsearch-result-index {
    .column.main {
        .products.wrapper {
            margin-left: 0 !important;
        }
    }
}