.slider {
    @include element('title') {
        font-family: "Poppins", sans-serif;
        font-size: 30px;
        font-weight: 500;
        color: $color-text-2;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 50px;
        @include modifier('primary') {
            font-size: 48px;
            line-height: 1;
            font-weight: 400;
            color: $color-primary;
            margin-top: 0;
            margin-bottom: 10px;
            @media screen and (max-width: 767px) {
                font-size: 30px;
            }
        }
    }

    @include element('subtitle') {
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        line-height: 1;
        font-weight: 400;
        text-align: center;
        color: $color-text-6;
        text-transform: none;
        margin-top: 0;
        margin-bottom: 40px;
    }

    @include element('prev') {
        @include retina-sprite($slider-prev-group);
        position: absolute;
        left: -100px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        @media screen and (max-width: 991px) {
            display: none !important;
        }
    }

    @include element('next') {
        @include retina-sprite($slider-next-group);
        position: absolute;
        right: -100px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        @media screen and (max-width: 991px) {
            display: none !important;
        }
    }

    @include element('slider') {
        @include modifier('product') {
            .slick-slide {
                margin: 0 15px;
            }

            .slick-list {
                margin: 0 -15px;
            }
        }
    }

    // Slick : Vertical align slides
    .slick-initialized .slick-slide {
        float: none;
        display: inline-block;
        vertical-align: middle;
        img {
            margin: 0 auto;
            padding-left: 20px;
            padding-right: 20px;

        }
    }
}

.owl-carousel {
    margin-left: -12px !important;
    margin-right: -12px !important;
}