.w-collapse {
    @include element('title') {
        font-family: "Poppins", sans-serif;
        font-size: 24px;
        line-height: 24px;
        font-weight: 500;
        color: $color-text-2;
        text-transform: uppercase;
        display: block;
        padding: 25px 0;
        border-bottom: 1px solid $color-border-1;
        &:hover {
            color: $color-primary;
            text-decoration: none;
        }
        &:visited, &:active, &:focus {
            color: $color-text-2;
            text-decoration: none;
        }

        &:after {
            content: '\f106';
            font-family: "FontAwesome";
            font-size: 16px;
            float: right;
        }

        &.collapsed {
            &:after {
                content: '\f107';
            }
        }

        @media screen and (max-width: 767px) {
            font-size: 17px;
            line-height: 17px;
            padding: 14px 0;
        }
    }

    @include element('desc') {
        padding-top: 30px;
        padding-left: 100px;
        padding-bottom: 60px;
        padding-right: 30px;
        border-bottom: 1px solid $color-border-1;

        font-size: 14px;
        font-style: italic;
        color: $color-text-3;

        @media screen and (max-width: 767px) {
            padding-left: 0;
            padding-right: 0;
            padding-top: 20px;
            padding-bottom: 30px;
        }

        @include modifier('full') {
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 30px;
        }
    }

    @include modifier('category') {
        .w-collapse {
            @include element('title') {
                font-size: 19px;
                line-height: 19px;
                font-weight: 600;
                color: $color-primary;
                padding: 20px 10px;
                &:hover {
                    color: white;
                    background: $color-primary;
                }

                &.collapsed {
                    &:after {
                        content: '\f107';
                    }
                }

                &:not(.collapsed) {
                    background: $color-primary;
                    color: white;
                }
            }

            @include element('desc') {
                padding-top: 30px;
                padding-left: 30px;
                padding-bottom: 50px;
                padding-right: 30px;
                border-bottom: 1px solid $color-border-1;
                font-size: 14px;
                color: $color-text-3;
            }
        }

        &.filter-options-content {
            padding: 0;
        }

        .w-collapse__categories, .items {
            > li {
                font-family: "Poppins", sans-serif;
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                text-transform: capitalize;
                color: $color-text-8;
                > a {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;

                    &:hover, &:focus, &:active {
                        color: $color-primary;
                    }
                }

                a {
                    &:visited {
                        color: $color-text-8;
                    }

                    &:hover {
                        background: none;
                    }
                }

                ul {
                    margin-top: 8px;
                    li {
                        color: $color-text-8;
                        font-family: "Lato", sans-serif;
                        font-style: italic;
                        font-weight: 400;
                        font-size: 16px;
                        text-transform: capitalize;
                        padding-left: 14px;
                        margin-bottom: 4px;
                        &:before {
                            content: '-';
                            float: left;
                            margin-right: 4px;
                        }

                        a {
                            &:hover, &:focus, &:active {
                                color: $color-primary;
                            }
                        }
                    }
                }
            }
        }
    }
}