.w-newsletter {
    background: $color-primary;
    padding-top: 70px;
    padding-bottom: 50px;
    @include element('title') {
        font-family: "Poppins", sans-serif;
        font-weight: 500;
        font-size: 30px;
        line-height: 1.2;
        text-align: center;
        text-transform: uppercase;
        color: white;
        margin-bottom: 40px;
        @media screen and (max-width: 767px) {
            font-size: 20px;
            margin-bottom: 16px;
        }
    }

    input[type=email] {
        height: 50px;
        color: $color-text-3;
        font-family: "Poppins", sans-serif;
        padding: 0 30px;
        width: calc(100% - 30px);
        @include placeholder() {
            color: $color-text-3;
            font-family: "Poppins", sans-serif;
        }

        @media screen and (max-width: 767px) {
            width: 100%;
        }
    }

    button {
        &.action.primary {
            background: $color-text-2;
            padding: 15px 25px;
            border-radius: 0;
            text-transform: uppercase;
            letter-spacing: 1px;
            @media screen and (max-width: 767px) {
                width: 100%;
            }
        }
    }

    .s-checkbox {
        margin-top: 20px;
        text-align: center;
        input[type="checkbox"] + label {
            font-family: "Poppins", sans-serif;
            font-weight: 300;
            color: white;
            font-size: 14px;
            &:before {
                border-color: $color-primary;
            }
        }
    }

    .input-group, .input-group-btn {
        @media screen and (max-width: 767px) {
            display: block;
            width: 100%;
        }
    }

    // Prevent error message from breaking the button alignment
    .input-group-btn {
        vertical-align: top;
    }
}