.pager {
    .pages {
        li {
            &.item {
                border: none;

                strong.page {
                    font-family: "Lato", sans-serif;
                    font-size: 14px;
                    background: $color-secondary !important;
                    span {
                        color: white;
                    }
                }

                span {
                    font-family: "Lato", sans-serif;
                    font-size: 14px;
                    color: $color-text-7;
                }

                > a {
                    border: none;
                    border-radius: 0;
                    &:hover {
                        background: none;
                    }
                }
            }

            &.current {
                border: none;
            }
        }
    }
}