@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Lato:300,400,400i,700,700i,900");
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,700");
h1 {
  font-family: "Poppins", sans-serif;
  font-size: 36px;
  font-weight: 500;
  color: #10acf4;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 65px; }
  @media screen and (max-width: 767px) {
    h1 {
      font-size: 26px;
      margin-bottom: 20px; } }

.page-main > .page-title-wrapper .page-title {
  font-family: "Poppins", sans-serif;
  font-size: 36px;
  font-weight: 500;
  color: #10acf4;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 65px; }
  @media screen and (max-width: 767px) {
    .page-main > .page-title-wrapper .page-title {
      font-size: 26px;
      margin-right: 0; } }

.dashboard-title {
  font-family: "Poppins", sans-serif;
  font-size: 36px;
  font-weight: 500;
  color: #10acf4;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 65px; }
  @media screen and (max-width: 767px) {
    .dashboard-title {
      font-size: 26px;
      margin-right: 0; } }

.h1__dashboard {
  font-size: 24px;
  text-align: left; }

.h2__title {
  font-family: "Poppins", sans-serif;
  font-size: 36px;
  line-height: 1;
  font-weight: 400;
  text-transform: uppercase;
  color: #464646;
  margin-top: 0;
  margin-bottom: 34px;
  text-align: center; }
  @media screen and (max-width: 767px) {
    .h2__title {
      font-size: 26px; } }

.h2__subtitle {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  line-height: 1;
  font-weight: 400;
  text-transform: uppercase;
  color: #10acf4; }

.l-loading {
  position: fixed;
  display: none;
  z-index: 9900;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5); }
  .l-loading--abs {
    position: absolute; }

.loader-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); }

.mul16 {
  height: 100px;
  width: 100px;
  -webkit-animation: mul16turn 2s infinite linear;
  animation: mul16turn 2s infinite linear; }

@-webkit-keyframes mul16turn {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes mul16turn {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

.m16c {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #10acf4;
  float: left;
  margin: 30px 3px 0; }

.m16c1 {
  -webkit-animation: m16c1anim 3s infinite ease-in-out;
  animation: m16c1anim 3s infinite ease-in-out; }

.m16c2 {
  -webkit-animation: m16c2anim 3s infinite ease-in-out;
  animation: m16c2anim 3s infinite ease-in-out; }

@-webkit-keyframes m16c1anim {
  0%, 100% {
    -webkit-transform: scale(0.25);
    transform: scale(0.25);
    background-color: #10acf4; }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
    background-color: #022536; } }

@keyframes m16c1anim {
  0%, 100% {
    -webkit-transform: scale(0.25);
    transform: scale(0.25);
    background-color: #67b4d4; }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
    background-color: #4b7b9f; } }

@-webkit-keyframes m16c2anim {
  0%, 100% {
    -webkit-transform: scale(1);
    transform: scale(1.5);
    background-color: #4b7b9f; }
  50% {
    -webkit-transform: scale(0.25);
    transform: scale(0.25);
    background-color: #67b4d4; } }

@keyframes m16c2anim {
  0%, 100% {
    -webkit-transform: scale(1);
    transform: scale(1.5);
    background-color: #4b7b9f; }
  50% {
    -webkit-transform: scale(0.25);
    transform: scale(0.25);
    background-color: #67b4d4; } }

/* Checkbox */
.s-checkbox input[type="checkbox"],
.s-checkbox input[type="radio"] {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }
  .s-checkbox input[type="checkbox"] + label:before,
  .s-checkbox input[type="radio"] + label:before {
    border-color: #10acf4;
    background-color: #f2f2f2; }
  .s-checkbox input[type="checkbox"]:active + label:before,
  .s-checkbox input[type="radio"]:active + label:before {
    transition-duration: 0s; }
  .s-checkbox input[type="checkbox"] + label,
  .s-checkbox input[type="radio"] + label {
    position: relative;
    padding-left: 2em;
    vertical-align: middle;
    user-select: none;
    cursor: pointer;
    font-weight: 400;
    font-size: 15px;
    font-family: "Lato", sans-serif;
    color: #777777; }
    .s-checkbox input[type="checkbox"] + label:before,
    .s-checkbox input[type="radio"] + label:before {
      box-sizing: content-box;
      content: '';
      color: #10acf4;
      position: absolute;
      top: 50%;
      left: 0;
      width: 18px;
      height: 18px;
      margin-top: -11px;
      border: 2px solid #cccccc;
      text-align: center;
      transition: all 0.4s ease; }
    .s-checkbox input[type="checkbox"] + label:after,
    .s-checkbox input[type="radio"] + label:after {
      box-sizing: content-box;
      content: '';
      background-color: #10acf4;
      position: absolute;
      top: 50%;
      left: 4px;
      width: 14px;
      height: 14px;
      margin-top: -7px;
      transform: scale(0);
      transform-origin: 50%;
      transition: transform 200ms ease-out; }

.s-checkbox input[type="checkbox"] + label:after {
  background-color: transparent;
  top: 50%;
  left: 4px;
  width: 13px;
  height: 4px;
  margin-top: -4px;
  border-style: solid;
  border-color: #10acf4;
  border-width: 0 0 2px 2px;
  border-image: none;
  transform: rotate(-45deg) scale(0); }

.s-checkbox input[type="checkbox"]:checked + label:after {
  content: '';
  transform: rotate(-45deg) scale(1);
  transition: transform 200ms ease-out; }

.s-checkbox input[type="checkbox"]:checked + label:before {
  animation: borderscale 200ms ease-in;
  background: white; }

.s-checkbox input[type="checkbox"]:checked + label:after {
  transform: rotate(-45deg) scale(1); }

@keyframes borderscale {
  50% {
    box-shadow: 0 0 0 2px #10acf4; } }

.s-form .form-group {
  margin: 0; }

.s-form .note {
  font-family: "Lato", sans-serif;
  font-size: 15px; }

.s-form .control {
  margin-bottom: 20px; }

.s-form input[type="text"],
.s-form input[type="password"],
.s-form input[type="url"],
.s-form input[type="tel"],
.s-form input[type="search"],
.s-form input[type="number"],
.s-form input[type="datetime"],
.s-form input[type="email"] {
  height: 50px;
  border-color: #ababab;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  font-family: "Lato", sans-serif;
  padding: 0 20px;
  color: #333333;
  font-size: 15px; }
  .s-form input[type="text"].mage-error,
  .s-form input[type="password"].mage-error,
  .s-form input[type="url"].mage-error,
  .s-form input[type="tel"].mage-error,
  .s-form input[type="search"].mage-error,
  .s-form input[type="number"].mage-error,
  .s-form input[type="datetime"].mage-error,
  .s-form input[type="email"].mage-error {
    border-color: #ed8380; }

.s-form .label {
  display: block;
  text-align: left;
  font-family: "Lato", sans-serif !important;
  font-size: 15px !important;
  color: #777777 !important;
  margin-bottom: 10px; }

.s-form .legend {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
  color: #464646; }

.s-form .back {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  line-height: 1;
  font-weight: 500;
  color: #355a98;
  text-decoration: none !important;
  margin-top: 20px;
  padding-bottom: 4px;
  border-bottom: 1px solid #355a98;
  text-transform: uppercase;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease; }
  .s-form .back:hover {
    text-decoration: none;
    color: #ff7200;
    border-color: #ff7200; }

.s-form__notice {
  text-align: right;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-style: italic;
  color: #777777;
  margin-top: 10px; }

.s-form__rgpd-notice {
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-style: italic;
  color: #777777;
  margin-top: -20px; }

.s-form select {
  background: url(../images/icn_arrow_select.png) no-repeat 0 0 #ffffff;
  background-position: right 30px center;
  box-sizing: border-box;
  color: #333333;
  display: block;
  font-size: 15px;
  font-weight: normal;
  padding: 0 45px 0 20px;
  position: relative;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  height: 50px;
  border: 1px solid #ababab;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0; }
  .s-form select.mage-error {
    border-color: #ed8380; }
  .s-form select.mbottom {
    margin-bottom: 20px; }

.s-form select::-ms-expand {
  display: none; }

.s-form.form-create-account .actions-toolbar > .primary {
  float: none !important; }

.s-form.form-create-account .pull-right {
  margin-top: 30px;
  float: none !important; }
  .s-form.form-create-account .pull-right .btn {
    width: 100%; }

@media screen and (max-width: 767px) {
  .s-form.forget .input-group, .s-form.forget .input-group-btn {
    display: block;
    width: 100%; }
    .s-form.forget .input-group input, .s-form.forget .input-group-btn input {
      width: 100%; } }

.s-form.forget .btn--large {
  padding: 15px 0 !important;
  width: 100%; }

.login-form-top h2 {
  font-style: normal;
  text-align: center; }

.login-form-top .btn {
  color: white !important;
  font-style: normal !important; }

@media screen and (max-width: 991px) {
  .login-form-top {
    display: none; } }

.btn {
  background: #10acf4;
  padding: 15px 25px;
  border-radius: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: white;
  border: 1px solid #10acf4;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease; }
  .btn:hover, .btn:focus {
    background: #0a9de0;
    color: white; }
  .btn:visited {
    color: white; }
  .btn--empty {
    background: white;
    border: 1px solid #10acf4;
    color: #10acf4 !important; }
    .btn--empty:hover {
      border: 1px solid #10acf4;
      color: white !important; }
  .btn--orange {
    background: #ff7200;
    border-color: #ff7200; }
    .btn--orange:hover, .btn--orange:focus {
      background: #e66700;
      border: 1px solid #e66700;
      color: white; }
  .btn--large {
    padding: 15px 50px;
    max-width: 100%; }
    @media screen and (max-width: 767px) {
      .btn--large {
        width: 100%;
        padding: 15px 0; } }
  .btn--full {
    padding: 15px;
    width: 100%;
    text-align: center; }
  .btn--all {
    display: inline-block;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    line-height: 1;
    font-weight: 400;
    color: #777777;
    text-transform: uppercase;
    padding: 20px 0;
    margin: 40px auto 0;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid #777777;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    width: 270px;
    max-width: 100%;
    text-align: center; }
    .btn--all:visited {
      color: #777777; }
    .btn--all:hover, .btn--all:focus {
      text-decoration: none;
      color: #10acf4;
      border-bottom: 1px solid #10acf4; }

.popup-cat-btn {
  position: absolute;
  top: -8px;
  left: 80px;
  right: 0;
  font-size: 25px; }

@media screen and (max-width: 767px) {
  .btn {
    width: 100%;
    padding: 15px 0 !important; }
    .btn--large {
      padding: 15px 0 !important; }
  .primary.pull-right {
    float: none !important; } }

/* Override theme header */
@media screen and (min-width: 992px) {
  .page-wrapper {
    padding-top: 300px;
    -webkit-transition: all 0.75s ease;
    -moz-transition: all 0.75s ease;
    -ms-transition: all 0.75s ease;
    -o-transition: all 0.75s ease;
    transition: all 0.75s ease; }
  body.on-scroll #ves-header > .ves-inner {
    height: 83px; }
  div.page-header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 100; }
    div.page-header .ves-header1 {
      background: none; }
      div.page-header .ves-header1 .header-content {
        padding-top: 0; }
      div.page-header .ves-header1 .ves-megamenu {
        background: #10acf4; }
        div.page-header .ves-header1 .ves-megamenu li.level0 > a {
          font-size: 18px; }
      div.page-header .ves-header1 .header-links-wrapper .header-links {
        font-size: 14px;
        font-family: "Lato", sans-serif;
        background: #ff7200;
        color: white; }
        div.page-header .ves-header1 .header-links-wrapper .header-links i {
          color: white; }
        div.page-header .ves-header1 .header-links-wrapper .header-links .options .action.toggle:after {
          font-size: 10px;
          line-height: 22px;
          margin-left: 8px; }
      div.page-header .ves-header1 .search-wrapper {
        background: #ff9600;
        font-size: 14px;
        font-family: "Lato", sans-serif;
        color: white; }
        div.page-header .ves-header1 .search-wrapper i {
          color: white; }
        div.page-header .ves-header1 .search-wrapper input {
          font-size: 14px;
          font-family: "Lato", sans-serif;
          background: none;
          color: white;
          opacity: 1 !important;
          font-style: normal;
          font-weight: 400; }
          div.page-header .ves-header1 .search-wrapper input::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            font-size: 14px;
            font-family: "Lato", sans-serif;
            background: none;
            color: white;
            opacity: 1 !important;
            font-style: normal;
            font-weight: 400; }
          div.page-header .ves-header1 .search-wrapper input:-ms-input-placeholder {
            /* IE 10+ */
            font-size: 14px;
            font-family: "Lato", sans-serif;
            background: none;
            color: white;
            opacity: 1 !important;
            font-style: normal;
            font-weight: 400; }
          div.page-header .ves-header1 .search-wrapper input:-moz-placeholder {
            /* Firefox 18- */
            font-size: 14px;
            font-family: "Lato", sans-serif;
            background: none;
            color: white;
            opacity: 1 !important;
            font-style: normal;
            font-weight: 400;
            opacity: 1; }
          div.page-header .ves-header1 .search-wrapper input::-moz-placeholder {
            /* Firefox 19+ */
            font-size: 14px;
            font-family: "Lato", sans-serif;
            background: none;
            color: white;
            opacity: 1 !important;
            font-style: normal;
            font-weight: 400;
            opacity: 1; }
      div.page-header .ves-header1 .setting-links {
        font-size: 14px;
        font-family: "Lato", sans-serif;
        background: #ffba00;
        color: white;
        text-transform: uppercase; }
        div.page-header .ves-header1 .setting-links i {
          color: white; }
        div.page-header .ves-header1 .setting-links .options .action.toggle:after {
          font-size: 10px;
          line-height: 22px;
          margin-left: 8px; }
      div.page-header .ves-header1 .minicart-wrapper {
        position: static !important; }
        div.page-header .ves-header1 .minicart-wrapper .action.showcart {
          margin-top: 0; }
    div.page-header .header-links .options.active, div.page-header .setting-links .options.active {
      overflow: initial; }
    div.page-header .header__top {
      margin-top: 10px; }
    div.page-header.navbar-fixed-top #ves-header {
      background: white;
      box-shadow: none; }
    div.page-header.navbar-fixed-top .header-content {
      padding-bottom: 18px !important; }
    div.page-header.navbar-fixed-top .header__top,
    div.page-header.navbar-fixed-top .h-infos__opening,
    div.page-header.navbar-fixed-top .h-infos__contact {
      position: absolute !important;
      top: -9999px !important;
      left: -9999px !important;
      -webkit-transition: all 0.75s ease;
      -moz-transition: all 0.75s ease;
      -ms-transition: all 0.75s ease;
      -o-transition: all 0.75s ease;
      transition: all 0.75s ease; }
    div.page-header.navbar-fixed-top .header__bottom {
      padding-top: 20px; }
    div.page-header.navbar-fixed-top.page-header .ves-header1 .logo-wrapper {
      text-align: left; }
    div.page-header.navbar-fixed-top .header__bottom-customer {
      display: block; }
      div.page-header.navbar-fixed-top .header__bottom-customer .header-links {
        padding-top: 9px; }
        div.page-header.navbar-fixed-top .header__bottom-customer .header-links .switcher-trigger {
          font-size: 18px !important;
          text-transform: uppercase !important;
          color: #ff7200 !important; }
          div.page-header.navbar-fixed-top .header__bottom-customer .header-links .switcher-trigger:after {
            vertical-align: middle; }
        div.page-header.navbar-fixed-top .header__bottom-customer .header-links .fa {
          color: #ff7200; }
  .store-language {
    color: #333333;
    text-transform: none; }
    .store-language .selected {
      font-style: normal; }
  .header__bottom {
    background-color: white;
    padding-top: 40px;
    padding-bottom: 30px;
    display: -webkit-box;
    /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;
    /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;
    /* TWEENER - IE 10 */
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-flex-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center; }
  .header__infos {
    display: -webkit-box;
    /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;
    /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;
    /* TWEENER - IE 10 */
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-flex-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center; }
  .header__bottom-customer {
    display: none; } }

.minicart-wrapper .fa-shopping-cart:before {
  content: '';
  display: block;
  background-image: url(sprite.png);
  background-position: -428px -152px;
  width: 27px;
  height: 26px; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .minicart-wrapper .fa-shopping-cart:before {
      background-image: url(sprite@2x.png);
      background-size: 456px 367px; } }

.minicart-wrapper .action.showcart .cart-title {
  font-size: 23px;
  line-height: 1;
  text-transform: uppercase;
  font-style: normal;
  color: #777777; }

.h-infos__opening {
  display: inline-block;
  font-size: 18px;
  line-height: 1;
  font-family: "Lato", sans-serif;
  color: #999999;
  text-transform: uppercase;
  padding-right: 20px; }
  @media screen and (min-width: 992px) and (max-width: 1050px) {
    .h-infos__opening {
      font-size: 14px; } }

.h-infos__hours {
  display: inline-block;
  padding: 0 15px 0 20px;
  border-left: 1px solid #ababab;
  border-right: 1px solid #ababab; }

.h-infos__contact {
  font-size: 23px;
  line-height: 1;
  font-family: "Poppins", sans-serif;
  color: #777777;
  text-transform: uppercase;
  letter-spacing: 2px; }
  @media screen and (min-width: 992px) and (max-width: 1050px) {
    .h-infos__contact {
      font-size: 20px; } }

.h-infos__phone {
  font-size: 24px;
  line-height: 1;
  font-family: "Lato", sans-serif;
  font-weight: 900;
  color: #ff7200; }
  .h-infos__phone a {
    font-size: 24px;
    line-height: 1;
    font-family: "Lato", sans-serif;
    font-weight: 900;
    color: #ff7200; }
  .h-infos__phone i {
    font-size: 22px !important;
    margin-right: 5px;
    line-height: 1;
    color: #ff7200 !important; }
    @media screen and (min-width: 992px) and (max-width: 1050px) {
      .h-infos__phone i {
        font-size: 20px; } }
  @media screen and (min-width: 992px) and (max-width: 1050px) {
    .h-infos__phone {
      font-size: 20px; } }

.logo img {
  max-width: 200px; }

@media screen and (max-width: 991px) {
  .page-header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1; }
    .page-header .ves-header1 {
      background: white !important; }
    .page-header.navbar-fixed-top .ves-header1 .minicart-wrapper .action.showcart {
      margin-top: 0; }
    .page-header .header-links .options li a {
      color: #10acf4; }
  .page-wrapper {
    padding-top: 150px;
    -webkit-transition: all 0.75s ease;
    -moz-transition: all 0.75s ease;
    -ms-transition: all 0.75s ease;
    -o-transition: all 0.75s ease;
    transition: all 0.75s ease; }
  .ves-menutop .nav-sections-item-title a:hover {
    color: #ff7200; } }

/* app/code/Ilio/Theme/view/frontend/web/template/minicart/content.html */
.minicart-wrapper .block-minicart {
  min-width: 0; }

.minicart-wrapper .actions a {
  color: white; }
  .minicart-wrapper .actions a:hover, .minicart-wrapper .actions a:focus, .minicart-wrapper .actions a:active, .minicart-wrapper .actions a:visited {
    color: white; }

.w-item {
  position: relative; }
  .w-item .total-qty {
    position: absolute;
    top: 0;
    left: 38px;
    float: none;
    margin: 0; }

.block-minicart .block-content > .actions > .primary {
  margin-left: 0;
  margin-right: 0; }

.minicart--degraded #top-cart-btn-checkout {
  display: none; }

.minicart--degraded .subtotal {
  visibility: hidden; }

.minicart--notlogged #top-cart-btn-checkout {
  display: none; }

.breadcrumbs {
  text-align: left !important;
  margin-bottom: 0; }
  .breadcrumbs .items {
    font-size: 14px; }
  .breadcrumbs .item {
    text-transform: none !important; }
    .breadcrumbs .item a {
      color: #858585;
      font-style: italic;
      text-decoration: underline; }
      .breadcrumbs .item a:visited {
        color: #858585; }
      .breadcrumbs .item a:hover, .breadcrumbs .item a:active {
        color: #10acf4; }
    .breadcrumbs .item strong {
      font-weight: 700;
      font-style: italic;
      color: #858585; }
    .breadcrumbs .item:last-child a {
      font-weight: bold; }

.pager .pages li.item {
  border: none; }
  .pager .pages li.item strong.page {
    font-family: "Lato", sans-serif;
    font-size: 14px;
    background: #ff7200 !important; }
    .pager .pages li.item strong.page span {
      color: white; }
  .pager .pages li.item span {
    font-family: "Lato", sans-serif;
    font-size: 14px;
    color: #605959; }
  .pager .pages li.item > a {
    border: none;
    border-radius: 0; }
    .pager .pages li.item > a:hover {
      background: none; }

.pager .pages li.current {
  border: none; }

.w-newsletter {
  background: #10acf4;
  padding-top: 70px;
  padding-bottom: 50px; }
  .w-newsletter__title {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 30px;
    line-height: 1.2;
    text-align: center;
    text-transform: uppercase;
    color: white;
    margin-bottom: 40px; }
    @media screen and (max-width: 767px) {
      .w-newsletter__title {
        font-size: 20px;
        margin-bottom: 16px; } }
  .w-newsletter input[type=email] {
    height: 50px;
    color: #777777;
    font-family: "Poppins", sans-serif;
    padding: 0 30px;
    width: calc(100% - 30px); }
    .w-newsletter input[type=email]::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #777777;
      font-family: "Poppins", sans-serif; }
    .w-newsletter input[type=email]:-ms-input-placeholder {
      /* IE 10+ */
      color: #777777;
      font-family: "Poppins", sans-serif; }
    .w-newsletter input[type=email]:-moz-placeholder {
      /* Firefox 18- */
      color: #777777;
      font-family: "Poppins", sans-serif;
      opacity: 1; }
    .w-newsletter input[type=email]::-moz-placeholder {
      /* Firefox 19+ */
      color: #777777;
      font-family: "Poppins", sans-serif;
      opacity: 1; }
    @media screen and (max-width: 767px) {
      .w-newsletter input[type=email] {
        width: 100%; } }
  .w-newsletter button.action.primary {
    background: #464646;
    padding: 15px 25px;
    border-radius: 0;
    text-transform: uppercase;
    letter-spacing: 1px; }
    @media screen and (max-width: 767px) {
      .w-newsletter button.action.primary {
        width: 100%; } }
  .w-newsletter .s-checkbox {
    margin-top: 20px;
    text-align: center; }
    .w-newsletter .s-checkbox input[type="checkbox"] + label {
      font-family: "Poppins", sans-serif;
      font-weight: 300;
      color: white;
      font-size: 14px; }
      .w-newsletter .s-checkbox input[type="checkbox"] + label:before {
        border-color: #10acf4; }
  @media screen and (max-width: 767px) {
    .w-newsletter .input-group, .w-newsletter .input-group-btn {
      display: block;
      width: 100%; } }
  .w-newsletter .input-group-btn {
    vertical-align: top; }

/* app/design/frontend/Ilio/saphore/Ilio_Theme/templates/footer.phtml */
.footer {
  background: #2b2b28;
  padding-top: 60px;
  padding-bottom: 100px; }
  .footer__container {
    margin-top: 70px; }

/* app/design/frontend/Ilio/saphore/Ilio_Theme/templates/footer/pictos.phtml */
.f-pictos__icon {
  margin: 0 auto 30px auto; }
  .f-pictos__icon--1 {
    background-image: url(sprite.png);
    background-position: 0px 0px;
    width: 152px;
    height: 152px; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .f-pictos__icon--1 {
        background-image: url(sprite@2x.png);
        background-size: 456px 367px; } }
  .f-pictos__icon--2 {
    background-image: url(sprite.png);
    background-position: -152px 0px;
    width: 152px;
    height: 152px; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .f-pictos__icon--2 {
        background-image: url(sprite@2x.png);
        background-size: 456px 367px; } }
  .f-pictos__icon--3 {
    background-image: url(sprite.png);
    background-position: 0px -152px;
    width: 152px;
    height: 152px; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .f-pictos__icon--3 {
        background-image: url(sprite@2x.png);
        background-size: 456px 367px; } }
  .f-pictos__icon--4 {
    background-image: url(sprite.png);
    background-position: -152px -152px;
    width: 152px;
    height: 152px; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .f-pictos__icon--4 {
        background-image: url(sprite@2x.png);
        background-size: 456px 367px; } }
  .f-pictos__icon--5 {
    background-image: url(sprite.png);
    background-position: -304px 0px;
    width: 152px;
    height: 152px; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .f-pictos__icon--5 {
        background-image: url(sprite@2x.png);
        background-size: 456px 367px; } }

.f-pictos__title {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  color: white;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 20px; }

/* app/design/frontend/Ilio/saphore/Ilio_Theme/templates/footer/links.phtml */
.f-links {
  margin: 50px 0;
  padding: 40px 0;
  border-top: 1px solid #464646;
  border-bottom: 1px solid #464646; }
  .f-links ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    text-align: center; }
    .f-links ul li {
      display: inline-block;
      padding: 0 50px;
      margin-bottom: 0; }
      @media screen and (max-width: 991px) {
        .f-links ul li {
          padding: 0 20px; } }
      @media screen and (max-width: 767px) {
        .f-links ul li {
          display: block;
          padding: 0; }
          .f-links ul li:not(:last-child) {
            margin-bottom: 20px; } }
      .f-links ul li a {
        font-size: 16px;
        line-height: 1;
        font-family: "Poppins", sans-serif;
        color: white;
        font-weight: 500;
        text-transform: uppercase;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease; }

/* app/design/frontend/Ilio/saphore/Ilio_Theme/templates/footer/description.phtml */
.f-description__title {
  font-size: 24px;
  line-height: 1;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  color: #ababab;
  margin-top: 0;
  margin-bottom: 40px; }

.f-description__text {
  font-size: 14px;
  line-height: 1.8;
  font-family: "Poppins", sans-serif;
  color: #ababab;
  text-align: justify; }

.f-description__bottom {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;
  /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-flex-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 50px; }
  @media screen and (max-width: 991px) {
    .f-description__bottom {
      display: block; } }

.f-description__flag {
  background-image: url(sprite.png);
  background-position: -304px -152px;
  width: 124px;
  height: 97px;
  float: left;
  margin-right: 36px;
  flex-grow: 0;
  flex-shrink: 0; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .f-description__flag {
      background-image: url(sprite@2x.png);
      background-size: 456px 367px; } }
  @media screen and (max-width: 991px) {
    .f-description__flag {
      float: none;
      margin: 0 auto; } }

.f-description__flag-text {
  font-size: 14px;
  line-height: 1.8;
  font-family: "Poppins", sans-serif;
  color: white;
  text-align: justify; }

/* app/design/frontend/Ilio/saphore/Ilio_Theme/templates/footer/sitemap.phtml */
.f-sitemap__title {
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 45px;
  color: white; }

.f-sitemap ul {
  margin-bottom: 50px; }
  .f-sitemap ul li a {
    font-size: 14px;
    font-family: "Poppins", sans-serif;
    color: white;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease; }
    .f-sitemap ul li a:before {
      content: '-';
      margin-right: 5px; }

/* app/design/frontend/Ilio/saphore/Ilio_Theme/templates/footer/mentions.phtml */
.f-mentions ul {
  margin: 0;
  padding: 0;
  list-style-type: none; }
  .f-mentions ul li {
    display: inline-block;
    margin-bottom: 0; }
    .f-mentions ul li a {
      font-size: 14px;
      line-height: 1;
      font-family: "Poppins", sans-serif;
      color: #858585;
      font-weight: 500;
      text-transform: uppercase;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease; }
    .f-mentions ul li:not(:last-child):after {
      content: '-';
      margin: 0 3px 0 5px;
      font-size: 14px;
      line-height: 1;
      font-family: "Poppins", sans-serif;
      color: #858585;
      font-weight: 500; }
      @media screen and (max-width: 767px) {
        .f-mentions ul li:not(:last-child):after {
          content: none; } }
    @media screen and (max-width: 767px) {
      .f-mentions ul li {
        display: block; } }

@media screen and (max-width: 767px) {
  .f-mentions {
    text-align: center; } }

#notice-cookie {
  position: fixed;
  bottom: 0;
  left: 0;
  background: #2b2b28;
  padding: 17px 50px;
  width: 100%;
  z-index: 10000; }
  #notice-cookie .notice-cookie-content__big {
    font-weight: bold;
    font-size: 14px; }
  @media screen and (max-width: 991px) {
    #notice-cookie .notice-cookie-content__text {
      margin-bottom: 20px; } }
  #notice-cookie .notice-cookie-content__small {
    font-size: 12px; }
  #notice-cookie .notice-cookie-content__btns {
    display: flex;
    justify-content: flex-end; }
    @media screen and (max-width: 991px) {
      #notice-cookie .notice-cookie-content__btns {
        justify-content: center; } }
    #notice-cookie .notice-cookie-content__btns a:last-of-type {
      margin-left: 25px; }

.w-collapse__title {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  line-height: 24px;
  font-weight: 500;
  color: #464646;
  text-transform: uppercase;
  display: block;
  padding: 25px 0;
  border-bottom: 1px solid #d8d8d8; }
  .w-collapse__title:hover {
    color: #10acf4;
    text-decoration: none; }
  .w-collapse__title:visited, .w-collapse__title:active, .w-collapse__title:focus {
    color: #464646;
    text-decoration: none; }
  .w-collapse__title:after {
    content: '\f106';
    font-family: "FontAwesome";
    font-size: 16px;
    float: right; }
  .w-collapse__title.collapsed:after {
    content: '\f107'; }
  @media screen and (max-width: 767px) {
    .w-collapse__title {
      font-size: 17px;
      line-height: 17px;
      padding: 14px 0; } }

.w-collapse__desc {
  padding-top: 30px;
  padding-left: 100px;
  padding-bottom: 60px;
  padding-right: 30px;
  border-bottom: 1px solid #d8d8d8;
  font-size: 14px;
  font-style: italic;
  color: #777777; }
  @media screen and (max-width: 767px) {
    .w-collapse__desc {
      padding-left: 0;
      padding-right: 0;
      padding-top: 20px;
      padding-bottom: 30px; } }
  .w-collapse__desc--full {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 30px; }

.w-collapse--category .w-collapse__title {
  font-size: 19px;
  line-height: 19px;
  font-weight: 600;
  color: #10acf4;
  padding: 20px 10px; }
  .w-collapse--category .w-collapse__title:hover {
    color: white;
    background: #10acf4; }
  .w-collapse--category .w-collapse__title.collapsed:after {
    content: '\f107'; }
  .w-collapse--category .w-collapse__title:not(.collapsed) {
    background: #10acf4;
    color: white; }

.w-collapse--category .w-collapse__desc {
  padding-top: 30px;
  padding-left: 30px;
  padding-bottom: 50px;
  padding-right: 30px;
  border-bottom: 1px solid #d8d8d8;
  font-size: 14px;
  color: #777777; }

.w-collapse--category.filter-options-content {
  padding: 0; }

.w-collapse--category .w-collapse__categories > li, .w-collapse--category .items > li {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  text-transform: capitalize;
  color: #5f5f5f; }
  .w-collapse--category .w-collapse__categories > li > a, .w-collapse--category .items > li > a {
    font-style: normal;
    font-weight: 400;
    font-size: 16px; }
    .w-collapse--category .w-collapse__categories > li > a:hover, .w-collapse--category .w-collapse__categories > li > a:focus, .w-collapse--category .w-collapse__categories > li > a:active, .w-collapse--category .items > li > a:hover, .w-collapse--category .items > li > a:focus, .w-collapse--category .items > li > a:active {
      color: #10acf4; }
  .w-collapse--category .w-collapse__categories > li a:visited, .w-collapse--category .items > li a:visited {
    color: #5f5f5f; }
  .w-collapse--category .w-collapse__categories > li a:hover, .w-collapse--category .items > li a:hover {
    background: none; }
  .w-collapse--category .w-collapse__categories > li ul, .w-collapse--category .items > li ul {
    margin-top: 8px; }
    .w-collapse--category .w-collapse__categories > li ul li, .w-collapse--category .items > li ul li {
      color: #5f5f5f;
      font-family: "Lato", sans-serif;
      font-style: italic;
      font-weight: 400;
      font-size: 16px;
      text-transform: capitalize;
      padding-left: 14px;
      margin-bottom: 4px; }
      .w-collapse--category .w-collapse__categories > li ul li:before, .w-collapse--category .items > li ul li:before {
        content: '-';
        float: left;
        margin-right: 4px; }
      .w-collapse--category .w-collapse__categories > li ul li a:hover, .w-collapse--category .w-collapse__categories > li ul li a:focus, .w-collapse--category .w-collapse__categories > li ul li a:active, .w-collapse--category .items > li ul li a:hover, .w-collapse--category .items > li ul li a:focus, .w-collapse--category .items > li ul li a:active {
        color: #10acf4; }

.doc__categories {
  background: #d8d8d8;
  padding: 50px;
  margin-top: 25px; }
  .doc__categories ul li a {
    display: block;
    background: white;
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;
    padding: 10px;
    margin-bottom: 15px;
    border-right: 15px solid #d8d8d8;
    color: #777777;
    text-decoration: none;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease; }
    .doc__categories ul li a:hover, .doc__categories ul li a.doc__toggler--active {
      background: #ff7200;
      color: white;
      text-decoration: none;
      border-color: #ff7200;
      margin-left: 15px; }
  .doc__categories ul li:last-child {
    margin-bottom: 0; }
    .doc__categories ul li:last-child a {
      margin-bottom: 0; }
  @media (max-width: 991px) {
    .doc__categories {
      padding: 20px; }
      .doc__categories ul li a {
        border-right: 0; }
        .doc__categories ul li a:hover, .doc__categories ul li a.doc__toggler--active {
          margin-left: 0; } }

.doc__category--hide {
  display: none; }

.doc__category ul li a {
  font-style: normal;
  font-family: "Lato", sans-serif;
  font-size: 15px;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;
  /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-flex-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  color: #464646; }
  .doc__category ul li a .doc__link {
    font-size: 13px;
    font-weight: 700;
    color: #10acf4;
    text-transform: uppercase;
    margin-left: 20px;
    display: -webkit-box;
    /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;
    /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;
    /* TWEENER - IE 10 */
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-flex-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center; }
    .doc__category ul li a .doc__link i {
      font-size: 21px;
      margin-right: 5px; }
  .doc__category ul li a:hover, .doc__category ul li a:focus {
    text-decoration: none;
    color: #10acf4; }

.doc__category ul li:before {
  content: '-';
  margin-right: 5px;
  float: left; }

@media (max-width: 767px) {
  .doc__category ul li a {
    display: block;
    padding-bottom: 15px;
    border-bottom: 1px solid #ddd; }
    .doc__category ul li a .doc__link {
      display: block;
      margin-left: 0;
      margin-top: 10px; } }

.article__container {
  margin-bottom: 70px; }
  @media screen and (min-width: 1200px) {
    .article__container:nth-child(4n+5) {
      clear: both; } }
  @media screen and (min-width: 992px) and (max-width: 1199px) {
    .article__container:nth-child(3n+4) {
      clear: both; } }
  @media screen and (min-width: 768px) and (max-width: 991px) {
    .article__container:nth-child(2n+3) {
      clear: both; } }
  @media screen and (max-width: 767px) {
    .article__container .btn {
      width: 100%; } }

.article__img {
  position: relative;
  overflow: hidden;
  padding-bottom: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  margin-bottom: 20px; }
  .article__img img {
    position: absolute;
    opacity: 0;
    visibility: hidden; }
  .article__img--home {
    margin-bottom: 0; }

.article__date {
  font-family: "Lato", sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  color: #333333;
  margin-bottom: 10px; }
  .article__date--single {
    margin-bottom: 30px; }

.article__title {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #10acf4;
  text-transform: uppercase;
  margin-bottom: 20px; }
  .article__title--single {
    text-align: left;
    margin-bottom: 15px; }
  .article__title--home {
    font-family: "Poppins", sans-serif !important;
    font-size: 24px !important;
    font-weight: 500 !important;
    color: #464646 !important;
    text-transform: uppercase !important; }

.article__excerpt {
  font-family: "Lato", sans-serif;
  font-size: 14px;
  line-height: 1.6;
  color: #333333;
  margin-bottom: 20px;
  text-transform: none; }
  .article__excerpt p, .article__excerpt ul, .article__excerpt li {
    font-family: "Lato", sans-serif;
    font-size: 14px;
    line-height: 1.6;
    color: #333333;
    margin-bottom: 0;
    text-transform: none; }

.article__btn {
  color: white !important; }

.article__back {
  display: inline-block;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  color: #464646;
  text-transform: uppercase;
  padding: 20px 40px;
  margin: 40px auto 0 auto;
  border-bottom: 1px solid #464646;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease; }
  .article__back:visited {
    color: #464646; }
  .article__back:hover, .article__back:focus {
    text-decoration: none;
    color: #10acf4;
    border-color: #10acf4; }

.article__item--home {
  width: 100%;
  height: 100%; }

.article__skew {
  margin: 0 -70px;
  transform-origin: left center;
  background: #d8d8d8;
  transform: rotate(-5deg);
  margin-top: 200px; }
  @media screen and (max-width: 767px) {
    .article__skew {
      margin-top: 40px; } }

.home-actus {
  -webkit-transform: rotate(5deg);
  -moz-transform: rotate(5deg);
  -o-transform: rotate(5deg);
  -ms-transform: rotate(5deg);
  transform: rotate(5deg);
  padding: 70px 0 50px; }
  @media screen and (max-width: 767px) {
    .home-actus {
      padding: 50px 70px 30px; } }

/* app/design/frontend/Ilio/saphore/Magento_Customer/templates/account/navigation.phtml */
.l-navigation {
  background: #ededed;
  padding-top: 25px;
  padding-bottom: 25px; }
  .l-navigation ul li > a {
    display: block;
    padding: 25px 50px;
    background: #ededed;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #10acf4;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease; }
    .l-navigation ul li > a strong {
      font-family: "Poppins", sans-serif;
      font-size: 14px;
      font-weight: 500;
      color: #10acf4; }
    .l-navigation ul li > a:hover, .l-navigation ul li > a:focus {
      background: #fafafa;
      text-decoration: none; }
    @media screen and (max-width: 767px) {
      .l-navigation ul li > a {
        padding: 20px; } }
  .l-navigation ul li > strong {
    display: block;
    padding: 25px 50px;
    background: #fafafa;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #10acf4; }
    @media screen and (max-width: 767px) {
      .l-navigation ul li > strong {
        padding: 20px; } }
  .l-navigation ul li:nth-child(1) a:before, .l-navigation ul li:nth-child(1) strong:before {
    width: 30px;
    content: "";
    font-family: 'FontAwesome';
    float: left;
    margin-right: 14px;
    font-size: 26px;
    margin-top: -8px;
    position: relative; }
  .l-navigation ul li:nth-child(2) a:before, .l-navigation ul li:nth-child(2) strong:before {
    width: 30px;
    content: "";
    font-family: 'FontAwesome';
    float: left;
    margin-right: 14px;
    font-size: 26px;
    margin-top: -8px;
    position: relative; }
  .l-navigation ul li:nth-child(3) a:before, .l-navigation ul li:nth-child(3) strong:before {
    width: 30px;
    content: "";
    font-family: 'FontAwesome';
    float: left;
    margin-right: 14px;
    font-size: 26px;
    margin-top: -8px;
    position: relative; }
  .l-navigation ul li:nth-child(4) a:before, .l-navigation ul li:nth-child(4) strong:before {
    width: 30px;
    content: "";
    font-family: 'FontAwesome';
    float: left;
    margin-right: 14px;
    font-size: 26px;
    margin-top: -8px;
    position: relative; }
  .l-navigation ul li:nth-child(5) a:before, .l-navigation ul li:nth-child(5) strong:before {
    width: 30px;
    content: "";
    font-family: 'FontAwesome';
    float: left;
    margin-right: 14px;
    font-size: 26px;
    margin-top: -8px;
    position: relative; }
  .l-navigation ul li:nth-child(6) a:before, .l-navigation ul li:nth-child(6) strong:before {
    width: 30px;
    content: "";
    font-family: 'FontAwesome';
    float: left;
    margin-right: 14px;
    font-size: 26px;
    margin-top: -8px;
    position: relative; }
  .l-navigation ul li.current > a:before {
    content: none;
    display: none; }
  @media screen and (max-width: 991px) {
    .l-navigation {
      margin-bottom: 20px; } }

/* app/design/frontend/Ilio/saphore/Magento_Customer/templates/account/dashboard/info.phtml */
.dashboard__block p, .dashboard__block address {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  color: #333333; }

.dashboard__title {
  font-family: "Poppins", sans-serif;
  font-size: 19px;
  font-weight: 500;
  text-align: left;
  color: #10acf4;
  text-transform: uppercase; }

.dashboard__subtitle {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  color: #333333; }

.dashboard__links a {
  display: block;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: #ff7200;
  text-decoration: underline;
  margin-bottom: 10px; }
  .dashboard__links a i {
    float: left;
    margin-top: 2px;
    margin-right: 5px; }
    .dashboard__links a i.fa-lock {
      margin-right: 7px; }

.hotnews__img {
  position: relative;
  overflow: hidden;
  padding-bottom: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  margin-bottom: 20px; }
  .hotnews__img img {
    position: absolute;
    opacity: 0;
    visibility: hidden; }

.hotnews__title {
  font-family: "Lato", sans-serif;
  font-size: 18px;
  font-weight: 700;
  color: #464646;
  margin-top: 0;
  margin-bottom: 30px; }

.hotnews__date {
  font-family: "Lato", sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  color: #333333;
  margin-bottom: 26px; }

.hotnews__content {
  font-family: "Lato", sans-serif;
  font-size: 15px;
  line-height: 1.6;
  color: #777777;
  margin-bottom: 50px; }
  .hotnews__content p, .hotnews__content ul, .hotnews__content li {
    font-family: "Lato", sans-serif;
    font-size: 15px;
    line-height: 1.6;
    color: #777777;
    margin-bottom: 50px; }

.hotnews__item:not(:last-child) {
  border-bottom: 1px solid #d8d8d8;
  margin-bottom: 50px; }

.cart-summary {
  background: #f5f5f5;
  padding: 30px 40px; }
  .cart-summary > .title {
    font-family: "Lato", sans-serif;
    font-size: 18px;
    line-height: 1;
    font-weight: 700;
    color: #464646;
    margin-bottom: 30px; }
  .cart-summary .block > .title {
    font-family: "Lato", sans-serif;
    font-size: 15px;
    line-height: 1;
    color: #5f5f5f;
    margin-bottom: 20px; }
  .cart-summary .block.shipping .fa-angle-down {
    display: inline; }
  .cart-summary .block.shipping .fa-angle-up {
    display: none; }
  .cart-summary .block.shipping.active .fa-angle-down {
    display: none; }
  .cart-summary .block.shipping.active .fa-angle-up {
    display: inline; }
  .cart-summary .table > tbody > tr > td, .cart-summary .table > tbody > tr > th, .cart-summary .table > tfoot > tr > td, .cart-summary .table > tfoot > tr > th, .cart-summary .table > thead > tr > td, .cart-summary .table > thead > tr > th {
    border-top: 1px solid #999999;
    padding: 20px 0; }
  .cart-summary .cart-totals tbody .mark, .cart-summary .cart-totals tfoot .mark {
    font-family: "Lato", sans-serif;
    font-size: 15px;
    line-height: 1;
    color: #5f5f5f;
    margin-bottom: 20px;
    background: none; }
    .cart-summary .cart-totals tbody .mark .label, .cart-summary .cart-totals tfoot .mark .label {
      font-family: "Lato", sans-serif;
      font-size: 15px;
      line-height: 1;
      color: #5f5f5f;
      background: none; }
    .cart-summary .cart-totals tbody .mark .value, .cart-summary .cart-totals tfoot .mark .value {
      font-family: "Lato", sans-serif;
      font-size: 12px;
      line-height: 1;
      color: #777777;
      margin-bottom: 20px;
      background: none; }
  .cart-summary .cart-totals tbody .amount, .cart-summary .cart-totals tfoot .amount {
    font-family: "Lato", sans-serif;
    font-size: 15px;
    line-height: 1;
    font-weight: 700;
    color: #5f5f5f;
    margin-bottom: 20px; }
  .cart-summary .cart-totals .grand .amount strong {
    color: #ff7200; }

@media screen and (max-width: 767px) {
  .cart-summary {
    padding: 30px 10px; }
    .cart-summary .totals tbody > tr:not(:last-child) > td:last-child {
      border-top: 1px solid #999 !important; }
  .cart .btn {
    width: 100%; }
  .cart-container .form-cart .actions.main > .btn--empty {
    color: #10acf4;
    width: 100%; } }

@media screen and (max-width: 767px) and (max-width: 767px) {
  .cart-container .input-group, .cart-container .input-group-btn {
    display: block;
    width: 100%; }
    .cart-container .input-group .btn, .cart-container .input-group-btn .btn {
      width: 100%; } }

/* app/design/frontend/Ilio/saphore/Magento_Checkout/templates/cart/form.phtml */
.cart {
  border: 0; }
  .cart thead tr th {
    font-family: "Lato", sans-serif;
    font-size: 14px;
    line-height: 1;
    color: #777777;
    padding-bottom: 20px !important;
    font-weight: 400;
    text-align: center;
    border: 0; }
  .cart tbody {
    border-right: 1px solid #ddd;
    border-bottom: 0 !important; }
    .cart tbody td {
      padding: 0 10px !important;
      vertical-align: middle !important;
      line-height: 1 !important;
      text-align: center; }
      .cart tbody td:first-child {
        width: 100px;
        padding: 0 !important; }
        .cart tbody td:first-child .product-image-wrapper {
          padding-bottom: 100% !important; }
      .cart tbody td .action-delete .fa-trash {
        font-size: 20px; }
      .cart tbody td .product-item-name {
        min-height: 0 !important;
        margin: 0 !important; }
        .cart tbody td .product-item-name a {
          font-family: "Poppins", sans-serif;
          font-size: 14px;
          font-weight: 500;
          color: #10acf4;
          text-transform: uppercase; }
      .cart tbody td .product-item-details {
        vertical-align: middle !important;
        padding: 0 !important; }
      .cart tbody td .price {
        font-family: "Poppins", sans-serif;
        font-size: 14px !important;
        font-weight: 500 !important;
        color: #ff7200 !important; }
      .cart tbody td .qty .input-text {
        text-align: center;
        width: 45px; }
      .cart tbody td .action {
        color: #ff7200; }
    .cart tbody + tbody {
      border-top: 0; }

/* app/design/frontend/Ilio/saphore/Ilio_Theme/templates/homepage/info-blocks.phtml */
.info-block {
  padding: 50px; }
  .info-block a {
    display: block;
    overflow: hidden; }
    .info-block a:hover, .info-block a:focus {
      text-decoration: none; }
      .info-block a:hover .info-block__title, .info-block a:hover .info-block__text, .info-block a:hover .info-block__text p, .info-block a:focus .info-block__title, .info-block a:focus .info-block__text, .info-block a:focus .info-block__text p {
        font-weight: 500; }
  .info-block__title {
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    line-height: 1;
    font-weight: 400;
    color: white;
    text-transform: uppercase;
    margin-top: 0;
    transition: all 0.3s ease; }
  .info-block__text {
    transition: all 0.3s ease; }
    .info-block__text p {
      font-family: "Poppins", sans-serif;
      font-size: 12px;
      color: white;
      font-weight: 300;
      line-height: 1.6;
      transition: all 0.3s ease; }
  .info-block__content {
    display: -webkit-box;
    /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;
    /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;
    /* TWEENER - IE 10 */
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-flex-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center; }
  .info-block__img {
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 16px; }
  .info-block--1 {
    background: #2b90f8;
    padding-left: 15px;
    padding-right: 100px; }
  .info-block--2 {
    background: #0eadf7; }
  .info-block--3 {
    background: #34cdff; }
  @media (max-width: 1199px) {
    .info-block {
      padding: 50px 25px; }
      .info-block--1 {
        padding-right: 25px; } }

.info-blocks {
  position: relative;
  opacity: 0;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease; }
  .info-blocks:before {
    content: '';
    background: #2b90f8;
    position: absolute;
    left: 0;
    right: 50%;
    top: 0;
    bottom: 0;
    display: block; }
  .info-blocks:after {
    content: '';
    background: #34cdff;
    position: absolute;
    left: 50%;
    right: 0;
    top: 0;
    bottom: 0;
    display: block;
    z-index: -1; }
  @media (max-width: 991px) {
    .info-blocks:before, .info-blocks:after {
      content: none; }
    .info-blocks .container {
      width: 100%; } }

.slider__title {
  font-family: "Poppins", sans-serif;
  font-size: 30px;
  font-weight: 500;
  color: #464646;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 50px; }
  .slider__title--primary {
    font-size: 48px;
    line-height: 1;
    font-weight: 400;
    color: #10acf4;
    margin-top: 0;
    margin-bottom: 10px; }
    @media screen and (max-width: 767px) {
      .slider__title--primary {
        font-size: 30px; } }

.slider__subtitle {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  line-height: 1;
  font-weight: 400;
  text-align: center;
  color: #999999;
  text-transform: none;
  margin-top: 0;
  margin-bottom: 40px; }

.slider__prev {
  background-image: url(sprite.png);
  background-position: -63px -304px;
  width: 63px;
  height: 63px;
  position: absolute;
  left: -100px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .slider__prev {
      background-image: url(sprite@2x.png);
      background-size: 456px 367px; } }
  @media screen and (max-width: 991px) {
    .slider__prev {
      display: none !important; } }

.slider__next {
  background-image: url(sprite.png);
  background-position: 0px -304px;
  width: 63px;
  height: 63px;
  position: absolute;
  right: -100px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .slider__next {
      background-image: url(sprite@2x.png);
      background-size: 456px 367px; } }
  @media screen and (max-width: 991px) {
    .slider__next {
      display: none !important; } }

.slider__slider--product .slick-slide {
  margin: 0 15px; }

.slider__slider--product .slick-list {
  margin: 0 -15px; }

.slider .slick-initialized .slick-slide {
  float: none;
  display: inline-block;
  vertical-align: middle; }
  .slider .slick-initialized .slick-slide img {
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px; }

.owl-carousel {
  margin-left: -12px !important;
  margin-right: -12px !important; }

.slider-product {
  text-align: center; }
  .slider-product__content {
    margin-bottom: 20px; }
  .slider-product__image {
    width: 100%;
    height: 180px;
    border: 1px solid #bdbdbd;
    margin-bottom: 30px;
    overflow: hidden;
    position: relative; }
    .slider-product__image img {
      margin: 0 auto; }
  .slider-product__title {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
    color: #191c21;
    margin-top: 0;
    margin-bottom: 10px; }
    .slider-product__title:after {
      content: '';
      display: block;
      width: 8px;
      height: 1px;
      background: #777777;
      text-align: center;
      margin: 6px auto 0 auto; }
  .slider-product__price {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    color: #355a98;
    margin-bottom: 20px; }
    .slider-product__price .old-price {
      color: #858585;
      text-decoration: line-through;
      display: inline; }
    .slider-product__price .final-price {
      color: #355a98;
      display: inline; }
    .slider-product__price .price-box {
      display: inline; }
      .slider-product__price .price-box .price-container .price {
        font-size: 14px; }
  .slider-product__notice {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    padding: 8px 15px;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    line-height: 1;
    font-weight: 400;
    color: white;
    text-transform: uppercase;
    z-index: 1;
    background: #10acf4; }
    .slider-product__notice--new {
      background: #bd2f48; }
    .slider-product__notice--promo {
      background: #ffd200;
      color: #2b2b28; }

#home-products {
  padding-top: 80px;
  padding-bottom: 70px;
  background: white; }

/* app/design/frontend/Ilio/saphore/Magento_Catalog/template/product/view/attributes.phtml */
.p-attribute {
  background: white;
  padding: 20px 30px;
  margin-bottom: 15px; }
  .p-attribute__title {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: #333333;
    text-align: left; }
    .p-attribute__title span {
      color: #10acf4; }
  .p-attribute__content {
    position: relative;
    display: -webkit-box;
    /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;
    /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;
    /* TWEENER - IE 10 */
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-flex-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center; }

/* app/design/frontend/Ilio/saphore/Magento_Catalog/template/product/view/details.phtml */
.p-details {
  background: #e4e4e4; }
  .p-details__navigation {
    border-bottom: 1px solid #a6a6a6; }
    .p-details__navigation li {
      float: none;
      display: inline-block;
      *display: inline;
      /* ie7 fix */
      zoom: 1;
      /* hasLayout ie7 trigger */ }
      .p-details__navigation li.active a {
        color: #10acf4;
        background: none;
        border: none; }
        .p-details__navigation li.active a:after {
          content: '';
          display: block;
          width: 100%;
          height: 5px;
          background: #10acf4;
          position: absolute;
          left: 0;
          bottom: -2px; }
        .p-details__navigation li.active a:hover, .p-details__navigation li.active a:focus {
          color: #10acf4;
          background: none;
          border: none; }
      .p-details__navigation li a {
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
        color: #464646;
        padding: 25px 45px 20px 45px;
        margin: 0;
        line-height: 1;
        border: none;
        background: none; }
        .p-details__navigation li a:hover, .p-details__navigation li a:focus {
          color: #10acf4;
          background: none;
          border: none; }
  .p-details__content {
    padding: 30px; }

.cms-content h2 {
  font-family: "Lato", sans-serif;
  font-size: 18px;
  font-weight: 700;
  color: #333333; }

.cms-content p {
  font-family: "Lato", sans-serif;
  font-size: 14px;
  color: #333333; }

.cms-content ul, .cms-content ol {
  font-family: "Lato", sans-serif;
  font-size: 14px;
  color: #333333;
  list-style: initial;
  padding-left: 40px; }
  .cms-content ul li, .cms-content ol li {
    list-style: initial !important; }

.cms-content table td, .cms-content table th {
  font-family: "Lato", sans-serif;
  font-size: 14px;
  color: #333333; }

.cms-block__container h2 {
  font-family: "Poppins", sans-serif;
  font-size: 48px;
  line-height: 1;
  color: white;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 30px; }
  @media screen and (max-width: 991px) {
    .cms-block__container h2 {
      font-size: 28px; } }

.cms-block__container p {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 2;
  color: white;
  font-weight: 300; }

.cms-block__container .btn--all {
  color: white;
  border-color: white;
  font-weight: 300;
  margin-top: 0; }
  .cms-block__container .btn--all:hover, .cms-block__container .btn--all:focus {
    color: #ffba00;
    border-color: #ffba00; }

.cms-block__column {
  text-align: center; }
  .cms-block__column--blue {
    background: #10acf4;
    display: -webkit-box;
    /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;
    /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;
    /* TWEENER - IE 10 */
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-flex-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    padding: 50px; }
    @media screen and (max-width: 991px) {
      .cms-block__column--blue {
        padding: 50px;
        display: block; } }
    @media screen and (max-width: 767px) {
      .cms-block__column--blue {
        padding: 15px; } }
  .cms-block__column--image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    margin-bottom: 20px;
    -webkit-transition: background 0.3s ease;
    -moz-transition: background 0.3s ease;
    -ms-transition: background 0.3s ease;
    -o-transition: background 0.3s ease;
    transition: background 0.3s ease; }
    .cms-block__column--image img {
      opacity: 0;
      visibility: hidden; }
    .cms-block__column--image a {
      display: block; }

#cms-block-video {
  display: none; }
  #cms-block-video.embed-responsive-16by9 {
    padding-bottom: 56.25% !important; }

/* app/design/frontend/Ilio/saphore/Ilio_Theme/templates/homepage/domains.phtml */
#home-domains {
  padding-top: 90px;
  padding-bottom: 50px;
  position: relative; }
  #home-domains:before {
    content: '';
    display: block;
    background-image: url("../images/tracteur-1.png");
    width: 435px;
    height: 433px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%); }
    @media screen and (max-width: 991px) {
      #home-domains:before {
        display: none; } }
  #home-domains:after {
    content: '';
    display: block;
    background-image: url("../images/tracteur-2.png");
    width: 458px;
    height: 519px;
    position: absolute;
    top: 70%;
    left: 0;
    z-index: -1; }
    @media screen and (max-width: 991px) {
      #home-domains:after {
        display: none; } }

.h-domain__item {
  display: block;
  margin-bottom: 30px; }
  @media screen and (min-width: 992px) {
    .h-domain__item:nth-child(3n+4) {
      clear: both; } }
  @media screen and (min-width: 768px) and (max-width: 991px) {
    .h-domain__item:nth-child(2n+3) {
      clear: both; } }

.h-domain__content {
  background: white;
  border: 1px solid #bdbdbd;
  padding: 50px;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;
  /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-flex-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease; }
  .h-domain__content:hover, .h-domain__content:focus {
    text-decoration: none;
    border-color: #10acf4; }
    .h-domain__content:hover h3, .h-domain__content:focus h3 {
      color: #10acf4; }
  .h-domain__content img {
    margin-right: 20px; }
  .h-domain__content h3 {
    font-family: "Poppins", sans-serif;
    font-size: 28px;
    line-height: 1.1;
    font-weight: 400;
    text-transform: uppercase;
    color: #464646;
    margin: 0;
    transition: all 0.3s ease; }
    @media screen and (max-width: 767px) {
      .h-domain__content h3 {
        font-size: 18px; } }
  @media screen and (max-width: 991px) {
    .h-domain__content {
      padding: 30px; } }
  @media screen and (max-width: 767px) {
    .h-domain__content {
      padding: 20px; } }

.tyre-sel__form--single input[type=text] {
  margin-bottom: 0 !important; }

.tyre-sel__form--single .btn--full {
  padding: 14px 15px; }

.tyre-sel__container {
  background: white;
  position: relative; }

.tyre-sel__fields {
  padding: 40px 100px; }
  .tyre-sel__fields input[type=text] {
    background: url(../images/tyre-search-icon.png) right 9px center no-repeat #fff;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    line-height: 50px;
    font-weight: 400;
    color: #464646;
    margin-bottom: 25px;
    padding: 0 40px 0 15px; }
    .tyre-sel__fields input[type=text]::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      font-family: "Poppins", sans-serif;
      font-size: 14px;
      line-height: 50px;
      font-weight: 400;
      color: #464646; }
    .tyre-sel__fields input[type=text]:-ms-input-placeholder {
      /* IE 10+ */
      font-family: "Poppins", sans-serif;
      font-size: 14px;
      line-height: 50px;
      font-weight: 400;
      color: #464646; }
    .tyre-sel__fields input[type=text]:-moz-placeholder {
      /* Firefox 18- */
      font-family: "Poppins", sans-serif;
      font-size: 14px;
      line-height: 50px;
      font-weight: 400;
      color: #464646;
      opacity: 1; }
    .tyre-sel__fields input[type=text]::-moz-placeholder {
      /* Firefox 19+ */
      font-family: "Poppins", sans-serif;
      font-size: 14px;
      line-height: 50px;
      font-weight: 400;
      color: #464646;
      opacity: 1; }
  .tyre-sel__fields select {
    background: url(../images/icn_arrow_select.png) right 9px center no-repeat #fff;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    line-height: 1;
    font-weight: 400;
    color: #464646;
    margin-bottom: 20px;
    padding: 0 25px 0 15px; }
  .tyre-sel__fields .label_tyre {
    font-size: 14px;
    margin-bottom: 5px;
    padding-left: 10px; }
  @media screen and (max-width: 767px) {
    .tyre-sel__fields {
      padding: 15px; } }

.tyre-sel__submit {
  font-weight: 700 !important; }
  .tyre-sel__submit i {
    font-size: 20px;
    position: absolute;
    margin-left: 20px; }
  .tyre-sel__submit:hover {
    border: 1px solid #0a9de0 !important; }

.tyre-sel__cats {
  border-bottom: 1px solid #cdcdcd;
  text-align: center; }

.tyre-sel__cat {
  display: inline-block;
  position: relative; }
  .tyre-sel__cat input {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: -9999; }
    .tyre-sel__cat input:checked + label {
      background: #10acf4;
      color: white; }
  .tyre-sel__cat label {
    background: white;
    cursor: pointer;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    line-height: 1;
    font-weight: 500;
    color: #464646;
    text-align: center;
    padding: 20px 40px;
    margin: 0;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease; }
    .tyre-sel__cat label:hover {
      background: #10acf4;
      color: white; }
    @media screen and (max-width: 767px) {
      .tyre-sel__cat label {
        width: 100%; } }
  @media screen and (max-width: 767px) {
    .tyre-sel__cat {
      display: block; } }

.return__tabs {
  margin-bottom: 20px; }
  .return__tabs .btn:not(:last-child) {
    margin-right: 30px; }
  .return__tabs .btn:focus, .return__tabs .btn.active {
    background: #ff7200 !important;
    border-color: #ff7200 !important;
    color: white !important; }

.r-products__title {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #10acf4;
  text-transform: uppercase;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 40px; }

.r-product__item {
  overflow: hidden;
  clear: both;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }
  .r-product__item:not(:last-child) {
    margin-bottom: 50px; }
  .r-product__item:hover {
    text-decoration: none; }
    .r-product__item:hover .r-product__img img {
      border-color: #10acf4; }
    .r-product__item:hover .r-product__title {
      color: #10acf4; }

.r-product__column {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;
  /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  flex-flow: column; }
  @media screen and (max-width: 767px) {
    .r-product__column {
      display: block; } }

.r-product__img {
  position: relative; }
  .r-product__img img {
    border: 1px solid #d7d7d7;
    transition: all 0.3s ease; }

.r-product__title {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #191c21;
  margin-top: 0;
  margin-bottom: 16px;
  transition: all 0.3s ease; }
  @media screen and (max-width: 767px) {
    .r-product__title {
      margin-top: 10px; } }

.r-product__price {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #ff7200; }

/* app/design/frontend/Ilio/saphore/Magento_Catalog/template/product/list.phtml */
.l-product {
  text-align: center;
  margin-bottom: 50px; }
  .l-product:hover .l-product__img:before {
    opacity: 0.8; }
  .l-product:hover .l-product__actions {
    opacity: 1;
    visibility: visible;
    -webkit-transition: opacity 0.3s ease;
    -moz-transition: opacity 0.3s ease;
    -ms-transition: opacity 0.3s ease;
    -o-transition: opacity 0.3s ease;
    transition: opacity 0.3s ease; }
  .l-product__img {
    position: relative;
    margin-bottom: 20px; }
    .l-product__img img {
      border: 1px solid #d7d7d7;
      transition: all 0.3s ease; }
    .l-product__img:before {
      content: '';
      display: block;
      background: #10acf4;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      -webkit-transition: opacity 0.3s ease;
      -moz-transition: opacity 0.3s ease;
      -ms-transition: opacity 0.3s ease;
      -o-transition: opacity 0.3s ease;
      transition: opacity 0.3s ease; }
  .l-product__title {
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-weight: 600;
    color: #191c21;
    margin-top: 0;
    margin-bottom: 0;
    text-align: center; }
  .l-product__price {
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-weight: 600;
    color: #ff7200;
    text-align: center; }
  .l-product__form {
    display: inline-block; }
  .l-product__actions {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: -webkit-box;
    /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;
    /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;
    /* TWEENER - IE 10 */
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-flex-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    transition: opacity 0.5s ease; }
  .l-product__icons {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2; }
  .l-product__icon {
    display: inline-block;
    padding: 6px 10px;
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    text-transform: uppercase;
    color: white;
    font-weight: 600;
    margin-right: 5px; }
    .l-product__icon--new {
      background: #bd2f48; }
    .l-product__icon--sale {
      background: #ffd200;
      color: #333333; }
  .l-product__btn {
    position: relative;
    font-size: 20px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    transition: all 0.3s ease;
    border: 1px solid white; }
    .l-product__btn i {
      transition: all 0.3s ease;
      color: #10acf4;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%); }
    .l-product__btn:hover, .l-product__btn:focus {
      border: 1px solid white;
      background: #10acf4; }
      .l-product__btn:hover i, .l-product__btn:focus i {
        color: white; }
  .l-product__short-description {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    color: #10acf4; }
  @media screen and (min-width: 992px) {
    .l-product--md-3:nth-child(4n + 5) {
      clear: both; } }
  @media screen and (min-width: 768px) and (max-width: 991px) {
    .l-product--md-3:nth-child(2n + 3) {
      clear: both; } }
  @media screen and (min-width: 992px) {
    .l-product--md-4:nth-child(3n + 4) {
      clear: both; } }
  @media screen and (min-width: 768px) and (max-width: 991px) {
    .l-product--md-4:nth-child(2n + 3) {
      clear: both; } }

.filter--current {
  background: #efefef;
  margin-bottom: 20px; }
  .filter--current .remove:before {
    content: '\f057' !important;
    font-family: 'FontAwesome' !important;
    color: #ff7200 !important;
    font-size: 14px !important; }

.filter .filter-current .items {
  display: block !important; }

.toolbar {
  padding-bottom: 30px; }
  .toolbar .toolbar-amount {
    margin: 0;
    text-transform: none;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    color: #777777; }
  .toolbar .limiter .label, .toolbar .limiter .limiter-label, .toolbar .limiter .limiter-text {
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    line-height: 1;
    font-weight: 400;
    text-transform: uppercase;
    color: #777777; }
  .toolbar label.sorter-label {
    font-family: "Poppins", sans-serif !important;
    font-size: 12px !important;
    line-height: 1 !important;
    font-weight: 400 !important;
    text-transform: uppercase !important;
    color: #777777 !important; }

/* Magento_Checkout::progress-bar.html */
.opc-progress-bar {
  position: relative;
  text-align: center; }
  .opc-progress-bar:before {
    content: '';
    width: 100%;
    height: 1px;
    background: #dbdbdb;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%); }
  .opc-progress-bar .opc-progress-bar-item {
    width: 370px;
    max-width: 40%; }
    .opc-progress-bar .opc-progress-bar-item:before {
      display: none; }
    .opc-progress-bar .opc-progress-bar-item:not(:last-child) {
      margin-right: 30px; }
    .opc-progress-bar .opc-progress-bar-item._active > span {
      background: #10acf4;
      color: white; }
    .opc-progress-bar .opc-progress-bar-item > span {
      background: white;
      padding: 15px 25px;
      border-radius: 0;
      font-family: "Poppins", sans-serif;
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: #10acf4;
      border: 1px solid #10acf4; }
      .opc-progress-bar .opc-progress-bar-item > span:before, .opc-progress-bar .opc-progress-bar-item > span:after {
        display: none; }

/* Ilio_Theme::shipping.html */
.opc-wrapper .step-title {
  font-family: "Poppins", sans-serif;
  font-size: 19px;
  font-weight: 600;
  color: #10acf4;
  text-transform: uppercase;
  border: none; }

.opc-wrapper .shipping-address-item {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #333333; }
  .opc-wrapper .shipping-address-item.selected-item {
    border-color: #10acf4; }
    .opc-wrapper .shipping-address-item.selected-item:after {
      background-color: #10acf4; }

.table-checkout-shipping-method {
  width: 100%;
  margin-bottom: 20px; }
  .table-checkout-shipping-method tbody td {
    border: none;
    padding-top: 10px;
    padding-bottom: 10px; }

.opc-block-summary > .title {
  font-family: "Lato", sans-serif;
  font-size: 18px;
  font-weight: 700;
  color: #464646;
  border: none; }

.opc-block-summary .items-in-cart > .title strong {
  font-family: "Lato", sans-serif;
  font-size: 15px !important;
  font-weight: 700;
  color: #5f5f5f; }

.opc-block-summary .table-totals .mark, .opc-block-summary .table-totals th, .opc-block-summary .table-totals td {
  font-family: "Lato", sans-serif;
  font-size: 15px !important;
  font-weight: 400;
  color: #5f5f5f;
  padding: 5px 0; }
  .opc-block-summary .table-totals .mark .label, .opc-block-summary .table-totals th .label, .opc-block-summary .table-totals td .label {
    font-family: "Lato", sans-serif;
    font-size: 15px !important;
    font-weight: 400;
    color: #5f5f5f;
    padding: 5px 0; }
  .opc-block-summary .table-totals .mark .value, .opc-block-summary .table-totals th .value, .opc-block-summary .table-totals td .value {
    font-family: "Lato", sans-serif;
    font-size: 10px !important;
    font-weight: 400;
    color: #777777;
    padding: 5px 0; }

.opc-block-summary .table-totals .amount, .opc-block-summary .table-totals td.grandtotal {
  font-family: "Lato", sans-serif;
  font-size: 15px !important;
  font-weight: 700;
  color: #5f5f5f;
  padding: 5px 0;
  text-align: right; }

.opc-block-shipping-information {
  padding: 0; }
  .opc-block-shipping-information > .shipping-information {
    padding: 30px;
    border: 1px solid #dbdbdb; }
  .opc-block-shipping-information .shipping-information-title {
    font-family: "Lato", sans-serif;
    font-size: 18px;
    font-weight: 700;
    color: #464646;
    border: none;
    padding: 0;
    margin-bottom: 12px; }
  .opc-block-shipping-information .shipping-information-content {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #333333; }

.checkout-payment-method .payment-method-title .radio {
  float: left;
  margin-right: 10px; }

.checkout-payment-method .payment-option-content {
  padding: 0; }

.checkout-payment-method .payment-method-content > .actions-toolbar > .primary .action.primary {
  font-size: 14px;
  background: #10acf4;
  padding: 15px 50px;
  border-radius: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: white;
  border: 1px solid #10acf4;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  background: #ff7200;
  border-color: #ff7200; }
  .checkout-payment-method .payment-method-content > .actions-toolbar > .primary .action.primary:hover, .checkout-payment-method .payment-method-content > .actions-toolbar > .primary .action.primary:focus {
    background: #e66700;
    border: 1px solid #e66700;
    color: white; }

.minicart-items .product-item-details .price {
  color: #ff7200; }

.checkout-success .btn {
  margin-top: 20px; }

.discount-title {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #333333; }

.checkout-index-index .opc-block-summary .table-totals tr.totals-tax {
  display: none !important; }

.table-order-items thead tr th {
  padding: 0 0 10px 0;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  color: #777777; }
  .table-order-items thead tr th.subtotal {
    text-align: left; }

.table-order-items tbody tr td {
  border: 1px solid #d7d7d7 !important;
  padding: 25px 20px;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  color: #191c21; }
  .table-order-items tbody tr td.total, .table-order-items tbody tr td.price, .table-order-items tbody tr td.subtotal {
    font-weight: 600;
    color: #ff7200; }
    .table-order-items tbody tr td.total .price, .table-order-items tbody tr td.price .price, .table-order-items tbody tr td.subtotal .price {
      font-family: "Poppins", sans-serif;
      font-size: 14px;
      font-weight: 600;
      color: #ff7200; }

.order-date {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  color: #333333; }
  .order-date .label {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    color: #333333; }

ul.items.order-links {
  display: none; }

.order-details-items {
  float: none;
  padding: 0;
  border: 0; }
  .order-details-items .order-title > strong {
    font-family: "Poppins", sans-serif;
    font-size: 19px;
    font-weight: 600;
    color: #5f5f5f;
    text-transform: uppercase; }

.block-order-details-view .block-title {
  float: none;
  text-align: left;
  margin-bottom: 10px; }
  .block-order-details-view .block-title strong {
    font-family: "Poppins", sans-serif;
    font-size: 24px !important;
    font-weight: 600;
    text-transform: uppercase;
    color: #10acf4; }

.block-order-details-view .block-content .box-title {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #333333; }

.block-order-details-view .block-content .box-content {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #333333; }
  .block-order-details-view .block-content .box-content address {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #333333; }

.sales-order-view .page-title-wrapper .page-title {
  font-size: 24px;
  text-align: left; }

.ws-order-title {
  font-size: 24px; }

.account .toolbar .pages {
  position: static; }

@media screen and (max-width: 767px) {
  .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) > tbody > tr td,
  .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) > tbody > tr th {
    padding: 15px; } }

.my-contact {
  overflow: hidden;
  padding-bottom: 40px;
  margin-bottom: 40px;
  border-bottom: 1px solid #d8d8d8; }
  .my-contact__title {
    font-size: 18px;
    line-height: 1;
    font-weight: 700;
    margin-bottom: 18px; }
  .my-contact__name {
    font-size: 15px;
    color: #777777; }
  .my-contact__mail {
    font-size: 15px;
    color: #777777; }
  .my-contact .btn {
    float: right; }
    .my-contact .btn.visible-xs {
      float: none;
      width: 100%;
      margin-top: 10px; }

.modal-popup.confirm .action-dismiss {
  background: white;
  padding: 15px 25px;
  border-radius: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #10acf4;
  border: 1px solid #10acf4;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease; }
  .modal-popup.confirm .action-dismiss:hover, .modal-popup.confirm .action-dismiss:focus {
    background: #0a9de0;
    border: 1px solid #10acf4;
    color: white; }
  .modal-popup.confirm .action-dismiss:visited {
    color: white; }

.modal-popup.confirm .action-accept {
  background: #ff7200;
  padding: 15px 25px;
  border-radius: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: white;
  border: 1px solid #ff7200;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease; }
  .modal-popup.confirm .action-accept:hover, .modal-popup.confirm .action-accept:focus {
    background: #e66700;
    border: 1px solid #e66700;
    color: white; }
  .modal-popup.confirm .action-accept:visited {
    color: white; }

.modal-popup.tyreselector-modal .modal-content {
  max-height: 400px; }

body {
  font-family: "Lato", sans-serif;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.col-xs-15,
.col-sm-15,
.col-md-15,
.col-lg-15 {
  position: relative;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px; }

.col-xs-30,
.col-sm-30,
.col-md-30,
.col-lg-30 {
  position: relative;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px; }

.col-xs-15 {
  width: 20%;
  float: left; }

.col-xs-30 {
  width: 40%;
  float: left; }

.loading-mask {
  background: none; }

#maincontent {
  margin-top: 40px; }

@media (min-width: 768px) {
  .col-sm-15 {
    width: 20%;
    float: left; }
  .col-sm-30 {
    width: 40%;
    float: left; } }

@media (min-width: 992px) {
  .col-md-15 {
    width: 20%;
    float: left; }
  .col-md-30 {
    width: 40%;
    float: left; } }

@media (min-width: 1200px) {
  .col-lg-15 {
    width: 20%;
    float: left; }
  .col-lg-30 {
    width: 40%;
    float: left; } }

.form.password.reset,
.form.send.confirmation,
.form.password.forget,
.form.create.account {
  width: 100%;
  min-width: 0; }

/* app/design/frontend/Ilio/saphore/Magento_Customer/templates/form/login.phtml */
/* app/design/frontend/Ilio/saphore/Magento_Customer/templates/newcustomer.phtml */
.login-container .block {
  width: 100%;
  padding-top: 40px; }
  .login-container .block .block-title {
    padding-bottom: 30px; }
    .login-container .block .block-title strong {
      font-family: "Poppins", sans-serif;
      font-size: 24px !important;
      font-weight: 500;
      text-transform: uppercase;
      text-align: center;
      color: #464646; }
  .login-container .block.block-new-customer {
    padding-bottom: 60px;
    padding-left: 100px;
    padding-right: 100px;
    background: #d8d8d8; }
    .login-container .block.block-new-customer .block-new-customer__btn-top {
      margin-bottom: 10px; }
    .login-container .block.block-new-customer .btn {
      width: 270px;
      padding-left: 10px;
      padding-right: 10px;
      color: white; }
    .login-container .block.block-new-customer .block-title {
      border-color: #999999; }
    @media screen and (max-width: 767px) {
      .login-container .block.block-new-customer {
        padding-left: 15px;
        padding-right: 15px; }
        .login-container .block.block-new-customer .btn {
          width: 100%; } }

.login-container .remind {
  font-family: "Lato", sans-serif;
  font-style: italic;
  font-size: 14px;
  color: #355a98;
  text-decoration: underline;
  text-transform: lowercase; }

.login-container .fieldset:after {
  display: block;
  clear: both;
  text-align: right;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-style: italic;
  color: #777777;
  margin-top: 10px; }
  @media screen and (max-width: 767px) {
    .login-container .fieldset:after {
      padding-top: 10px; } }

.login-container #login-form .actions-toolbar {
  margin-bottom: 0; }

/* app/design/frontend/Ilio/saphore/Magento_Customer/templates/form/forgotpassword.phtml */
.forgot-pw input[type="email"] {
  width: calc(100% - 30px); }

.mb--25 {
  margin-bottom: 25px; }

@media (min-width: 992px) {
  .abs-shopping-cart-items-desktop,
  .block-cart-failed,
  .cart-container .cart-gift-item,
  .cart-container .form-cart {
    width: calc(100% - 355px) !important; }
  .cart-container .form-cart {
    padding-left: 0; }
  .cart-summary {
    width: 305px !important; } }

@media (max-width: 991px) {
  .abs-shopping-cart-items-desktop,
  .block-cart-failed,
  .cart-container .cart-gift-item,
  .cart-container .form-cart {
    width: 100% !important; }
  .cart-summary {
    width: 100% !important; }
  .cart-container .form-cart {
    padding-left: 0; }
  .cart-discount {
    width: 100%;
    padding-left: 0; } }

@media (min-width: 768px) {
  .cart-discount, #discount-form {
    width: 570px;
    padding-left: 0;
    max-width: 100%; }
    .cart-discount input, #discount-form input {
      width: calc(100% - 30px);
      height: 52px !important; }
    .cart-discount .discount-title, #discount-form .discount-title {
      font-family: "Poppins", sans-serif;
      font-size: 14px;
      font-weight: 600;
      color: #333333; } }

@media (max-width: 767px) {
  #co-payment-form .fieldset {
    display: block;
    width: 100%;
    padding: 0 15px; } }

@media screen and (max-width: 767px) and (max-width: 767px) {
  .cart-discount .input-group, .cart-discount .input-group-btn, #discount-form .input-group, #discount-form .input-group-btn {
    display: block;
    width: 100%; }
    .cart-discount .input-group .btn, .cart-discount .input-group-btn .btn, #discount-form .input-group .btn, #discount-form .input-group-btn .btn {
      margin-left: 0; } }

.cms-index-index {
  background-image: url("../images/home-bg.jpg");
  background-repeat: no-repeat;
  background-position: top center; }
  .cms-index-index .ves-menutop {
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
    box-sizing: border-box;
    width: 100%;
    -webkit-flex-grow: 1;
    flex-grow: 1;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
    -webkit-flex-basis: auto;
    flex-basis: auto; }
  .cms-index-index div.page-header .ves-header1 .header-content {
    padding: 0; }
  .cms-index-index div.page-header.navbar-fixed-top #ves-header {
    background: none; }
  .cms-index-index .navbar-fixed-top #ves-header {
    box-shadow: none; }
  .cms-index-index #tyreselector {
    margin-top: 80px;
    margin-bottom: 100px; }
  @media screen and (min-width: 1900px) {
    .cms-index-index {
      background-size: 100%; } }

.homepage {
  overflow: hidden; }

@media screen and (min-width: 1200px) {
  .cms-index-index .style-home3 .col-danger {
    /* Override home slider */ }
    .cms-index-index .style-home3 .col-danger .blog-posts .owl-item.active:nth-child(3n) {
      bottom: 30px; }
    .cms-index-index .style-home3 .col-danger .blog-posts#blog-posts--2 .owl-item.active {
      width: 398px !important; }
      .cms-index-index .style-home3 .col-danger .blog-posts#blog-posts--2 .owl-item.active .ves-post.post-item {
        float: none; }
        .cms-index-index .style-home3 .col-danger .blog-posts#blog-posts--2 .owl-item.active .ves-post.post-item .post-image {
          float: none;
          width: initial; }
        .cms-index-index .style-home3 .col-danger .blog-posts#blog-posts--2 .owl-item.active .ves-post.post-item .post-meta {
          float: left;
          padding: 0; }
      .cms-index-index .style-home3 .col-danger .blog-posts#blog-posts--2 .owl-item.active:first-child .ves-post.post-item .post-meta {
        width: initial; } }

@media screen and (max-width: 1199px) {
  .cms-index-index {
    background-size: auto 100vh; } }

@media screen and (min-width: 992px) {
  .cms-index-index #ves-header {
    background: none; } }

.product-info-main .page-title-wrapper .page-title {
  font-size: 18px;
  text-align: left;
  letter-spacing: 0; }

.product-info-main .page-title-wrapper .overview {
  margin-bottom: 0 !important; }
  .product-info-main .page-title-wrapper .overview .type {
    display: none; }
  .product-info-main .page-title-wrapper .overview .value {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: #10acf4;
    text-transform: uppercase;
    line-height: 1;
    margin-top: 0;
    margin-bottom: 0; }

.product-info-main .product-info-price {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #ff7200; }
  .product-info-main .product-info-price .stock {
    font-weight: 400; }

.product-info-main .stock {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  color: #333333;
  text-transform: lowercase;
  position: relative;
  padding-left: 14px; }
  .product-info-main .stock:before {
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #78c316;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%); }
  .product-info-main .stock.unavailable:before {
    background: red; }

.product-info-main .price-box {
  margin-bottom: 40px; }
  .product-info-main .price-box .weee .price {
    font-family: "Poppins", sans-serif;
    font-size: 18px; }

.product-info-main .product-add-form {
  float: none; }

.product-info-main .box-tocart {
  margin-bottom: 15px; }
  .product-info-main .box-tocart .field.qty label {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    color: #333333;
    text-transform: none;
    font-weight: 400 !important; }

.product-info-main .product-list-categories {
  margin-top: 25px; }
  .product-info-main .product-list-categories span {
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    color: #777777;
    display: block;
    float: none; }
  .product-info-main .product-list-categories a {
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    color: #777777;
    font-style: italic; }

.product.info.detailed {
  border-top: 0; }

.product .icon > span {
  border: none; }
  .product .icon > span span {
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    line-height: 1;
    text-transform: uppercase;
    color: white;
    font-weight: 600;
    background: #bd2f48;
    padding: 6px 10px; }

.p-column--left {
  padding-right: 55px;
  border-right: 1px solid #cecece; }

.p-column--right {
  padding-left: 55px; }

@media screen and (max-width: 991px) {
  .p-column--left {
    padding-right: 15px;
    border-right: none; }
  .p-column--right {
    padding-left: 15px; } }

@media screen and (max-width: 767px) {
  .p-column--left {
    width: 100%;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-grow: 1;
    flex-grow: 1;
    -webkit-flex-basis: 100%;
    flex-basis: 100%;
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1; } }

.l-column--left {
  padding-right: 0; }

.l-column--right {
  padding-left: 55px; }

@media screen and (max-width: 991px) {
  .l-column--left {
    padding-right: 15px; }
  .l-column--right {
    padding-left: 15px;
    border-left: none; } }

.category-tyreselector {
  margin-bottom: 20px; }
  .category-tyreselector #tyreselector {
    background: #ededed; }
  .category-tyreselector .tyre-sel__container {
    background: #ededed;
    position: relative; }
  .category-tyreselector .tyre-sel__cat label {
    background: #ededed; }
    .category-tyreselector .tyre-sel__cat label:hover {
      background: #10acf4; }
  .category-tyreselector .tyre-sel__fields input[type=text] {
    background: url(../images/tyre-search-icon.png) right 9px center no-repeat #ededed; }
  .category-tyreselector .tyre-sel__fields select {
    background: url(../images/icn_arrow_select.png) right 9px center no-repeat #ededed; }

.category-catalog {
  margin-bottom: 30px; }

.catalogsearch-result-index .column.main .products.wrapper {
  margin-left: 0 !important; }

.checkout-index-index .nav-sections {
  display: block; }

.field-select-billing select option:last-child {
  display: none; }

@media screen and (min-width: 768px) and (max-width: 991px) {
  .shipping-address-content {
    min-height: 150px; } }

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .shipping-address-content {
    min-height: 180px; } }

@media screen and (min-width: 1200px) {
  .shipping-address-content {
    min-height: 210px; } }

.account .toolbar .toolbar-amount {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #777777; }

.account .actions-toolbar {
  text-align: right; }
  .account .actions-toolbar .back {
    font-size: 14px;
    color: #777777;
    text-transform: uppercase;
    text-decoration: underline;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease; }
    .account .actions-toolbar .back:hover, .account .actions-toolbar .back:focus {
      color: #10acf4; }

.sales-order-view .order-status {
  font-size: 14px;
  font-weight: 700;
  font-style: italic;
  text-transform: lowercase;
  color: #ff7200; }

.sales-order-history .pages-items {
  width: 100%; }

.sales-order-history .toolbar-amount {
  float: none !important;
  margin-bottom: 20px; }

.sales-order-history .table-order-items tbody tr:hover {
  cursor: pointer; }

.label-degraded {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #ff7200; }
